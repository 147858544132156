@import "ext/normalize.css";
@import "misc/fonts";
@import "misc/text";

html, body {
	height: 100%;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;

	font: {
		family: ProximaNova;
		weight: 300;
		size: 16px;
	}
	color: #333;
	line-height: 1.35;
}

body._noScroll {
	overflow: hidden;
}

.w-100 {
	width: 100% !important;
}

.actionsTitle h1{
	font-size: 25px;
	font-weight: 600;
	text-transform: uppercase;
}

.actionsTitle h2{
	font-size: 25px;
	font-weight: 300;
	text-transform: uppercase;
	color: #999999;
}

@import "misc/animations";

@import "form/radio";
@import "form/checkbox";
@import "form/input";

@import "ext/bootstrap-grid.css";
@import "blocks/site";
@import "blocks/slashDecor";
@import "blocks/responsive";
@import "blocks/btn1";
@import "blocks/btn2";
@import "blocks/btn3";
@import "blocks/icons";
@import "blocks/indexPage";
@import "blocks/page";
@import "blocks/top";
@import "blocks/mainMenu";
@import "blocks/footer";
@import "blocks/footerMenu";
@import "blocks/crumbs";
@import "blocks/subscriptionForm";
@import "blocks/contents";
@import "blocks/steps";
@import "blocks/price";
@import "blocks/pageTop";
@import "blocks/catalogList";
@import "blocks/catalogItem";
@import "blocks/responsiveVideo";
@import "blocks/frame1";
@import "blocks/actionsList";
@import "blocks/actionsItem";
@import "blocks/popup";
@import "blocks/popupForm";
@import "blocks/searchForm";
@import "blocks/gallery";
@import "blocks/giftCard";
@import "blocks/subMenu";
@import "blocks/staff";
@import "blocks/equipment";
@import "blocks/folder";
@import "blocks/reviews";
@import "blocks/map";
@import "blocks/list1";
@import "blocks/certs";
@import "blocks/quantity";
@import "blocks/kCompare";
@import "blocks/catalogMenu";
@import "blocks/mobileMenu";
@import "blocks/list2";
@import "blocks/folder2";
@import "blocks/order";
@import "blocks/bdBanner";
@import "blocks/nyBanner";
@import "blocks/floatingBanner";
@import "blocks/tb1";
@import "blocks/youtube";
@import "block/happyBirthday";
@import "block/certPopup";
