.frame1 {
	background: #f4f4f4;
	text-align: center;
	padding: 40px 0;
	margin: 40px 0;
}

.frame1_wide {
	@extend .frame1;
	//border: 1px solid red;
	position: relative;
	padding-top: 20px;

	&:before, &:after {
		content: '';
		position: absolute;
		right: 100%;
		top: 0;
		bottom: 0;
		background: #f4f4f4;
		width: 1920px;
	}

	&:after {
		right: auto;
		left: 100%;
	}
}
