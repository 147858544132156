@import "../ext/mq";

.certs {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.certs-item {
	width: 48.42%;
	text-align: center;
	margin-bottom: 35px;

	@include mq($until: tablet) {
		//border: 1px solid red;
		width: 100%;
	}

	img {
		width: 100%;
	}
}

.certs-type {
	font-size: 15px;
	color: #a5a5a5;
	padding: 14px 0 6px;
}

.certs-name {
	font-size: 22px;
	color: #262626;
	font-weight: 600;
	text-transform: uppercase;
}

.certs-text {
	font-size: 15px;
	padding: 17px 0 20px;
}

.certs-footer {

}

.certs-price, .certs-quantity, .certs-btn {
	display: inline-block;
	vertical-align: middle;
	margin: 0 14px 20px;
}

.certs-price {
	font: {
		size: 18px;
		weight: 600;
	}
}

.certs-total {
	font-size: 22px;
}
