@font-face {
	font-family: 'OpenSans';
	src:
			url('/css/fonts/OpenSans-Regular-webfont.woff') format('woff'),
			url('/css/fonts/OpenSans-Regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src:
			url('/css/fonts/OpenSans-Bold-webfont.woff') format('woff'),
			url('/css/fonts/OpenSans-Bold-webfont.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'ProximaNova';
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
	url('/css/fonts/ProximaNova-Regular.woff') format('woff'),
	url('/css/fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 400; // normal
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
	url('/css/fonts/ProximaNova-Semibold.woff') format('woff'),
	url('/css/fonts/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600; // semi bold
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
	url('/css/fonts/ProximaNova-Bold.woff') format('woff'),
	url('/css/fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: 700; // bold
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
	url('/css/fonts/ProximaNova-Light.woff') format('woff'),
	url('/css/fonts/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300; // light
	font-style: normal;
}