.btn1 {
	display: inline-block;
	height: 45px;
	padding: 0 55px;
	background: #64c2c9;
	color: #fff;
	text-decoration: none;
	line-height: 45px;
	text-transform: uppercase;
	font: {
		size: 16px;
		weight: 300;
	}
	transition: .2s ease-out;

	&:hover {
		background: #47a0a7;
	}

	+.btn2, +.btn3 {
		margin-left: 10px;
	}
}
