.btn3 {
	display: inline-block;
	position: relative;
	top: 1px;
	height: 43px;
	padding: 0 30px;
	min-width: 170px;
	box-sizing: border-box;
	//background: #fff;
	color: #262626;
	border: 1px solid #262626;
	background: rgba(#000, 0);
	text-decoration: none;
	line-height: 43px;
	text-transform: uppercase;
	text-align: center;
	font: {
		size: 16px;
		weight: 300;
	}
	transition: .2s ease-out;

	&:hover {
		color: #47a0a7;
		border: 1px solid #47a0a7;
	}

	+.btn1, +.btn2 {
		margin-left: 10px;
	}
}

.btn3_transparent {
	border: 1px solid #47a0a7;
	background: transparent;
	color: inherit;
}

@import "../_sprite";

.img-link{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	float: left;
}

.img-link a{
	margin-left:12px;
}

@media screen and (max-width: 768px){
	.img-link{
		float:inherit
	}
}

@media screen and (max-width: 768px){
	.img-link img{
		margin-left: 0;
	}
}

@media screen and (max-width: 768px){
	.img-link a{
		margin-left: 0;
	}
}

.btn3_back {
	padding-left: 51px;

	&:after {
		content: '';
		position: absolute;
		left: 17px;
		top: 50%;
		margin-top: -$back-height/2;
		@include sprite($back)
	}
}


.text-white .btn3 {
	color: #fff;
	border-color: #fff;
	&:hover {
		color: #47a0a7;
		border-color: #47a0a7;
		//background: rgba(#000, .2);
	}
}
