.nyBanner {
	
	color: #fff;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	
	span {
		display: inline-block;
		padding: 7px 21px 6px 191px;
		background: url('/img/ny.png') left center no-repeat #39a7af;
	}

	@media (min-width: 800px) {
		position: fixed;
		z-index: 1;
		left: 50%;
		bottom: 0;
		max-width: 100%;
		transform: translateX(-50%);
		white-space: nowrap;
	}

	@media (max-width: 800px) {
		display: block;
		text-align: center;
		background: #262626;
		span {
			background: #39a7af;
			padding: 7px 21px 6px;
		}
	}
}
