.happyBirthday {
	max-width: 1007px;
	max-height: 560px;
	width: 100vw;
	height: 100vh;
	background: url('/userfiles/img/popup-bg.jpg') left center no-repeat;
	display: flex;
	align-items: center;
}

.happyBirthday-content {
	max-width: 360px;
	width: 90%;
	margin-left: 11.7%;
	color: #fff;
}

.happyBirthday-header {
	font-family: ProximaNova, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 41px;
	text-transform: uppercase;
	margin-bottom: 24px;
}

.happyBirthday-text {
	font-family: ProximaNova, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 64px;
}

.happyBirthday-btn {

}
