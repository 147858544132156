.folder {

}

.folder-body {
	display: none;
}

.folder-ctrl {

}

.folder-more {

}

.folder-less {
	display: none;
}
