@import '../sprite';
@import "../ext/mq";

.footer {
	@include mq($from: tablet){
		position: fixed;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.footer-fake {
	@include mq($until: tablet){
		display: none;
	}
}

.footer-top {
	background: #f4f4f4;
	padding: 53px 0;
}

.footer-content {
	width: 98%;
	max-width: 1720px;
	margin: 0 auto;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	//align-items: center;
}

.footer-menu {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: stretch;
	width: 62%;
	border-right: 1px solid #d0d0d0;
	box-sizing: border-box;

	@include mq($until: tablet) {
		display: none;
	}

}

.footer-menuItem {
	flex: 1;

	&:first-child {
		flex: 1.35;
	}
}

.footer-form {
	display: block;
	width: 38%;

	@include mq($until: tablet) {
		width: 100%;
		margin: 0 auto;
	}
}

.footer-bottom {
	background: #262626;
	height: 280px;
	color: #999;
	font-size: 12px;
	display: flex;
	align-items: center;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;

	@include mq($until: tablet) {
		height: auto;
		padding: 20px 0;
	}

	@media screen and (max-width: 1199px){
		height: 300px;
	}

	@media screen and (max-width: 840px){
		height: 450px;
	}

	.footer-content {
		@include mq($until: tablet) {
			display: block;
		}
	}
}

.footer-left {
	width: 33.333%;
	@include mq($until: tablet) {
		width: 100%;
		text-align: center;
	}
}

.footer-logo {
	img {
		max-width: 135px;
	}
}

.footer-copy {
	padding-top: 10px;
}

.footer-center {
	width: 33.333%;
	text-align: center;

	@include mq($until: tablet) {
		width: 100%;
		margin: 20px 0;
	}

	a {
		margin: 0 7px;
	}
}

//.footer-right {
//	width: 33.333%;
//	text-align: right;
//	@include mq($until: tablet) {
//		width: 100%;
//		text-align: center;
//	}
//}

.footer-idea {
	position: relative;

	//&:before {
	//	content: '';
	//	display: inline-block;
	//	vertical-align: middle;
	//	margin-right: 12px;
	//	@include sprite($idea);
	//}

	a {
		color: inherit;
	}

	p {
		font-size: 15px;

		span {
			display: inline-block;
		}
	}
}

.footer-icon-link{
	display: flex;
	justify-content: space-between;
	max-width: 190px;
	margin-top: 10px;
	text-align: center;

	@media screen and (max-width: 767px){
		margin: 10px auto 0;
	}

}

.footer-idea-left{
	margin-top: 5px;
}

.footer-idea{
	p:first-child{
		margin-top: 0;
	}
}

.footer-address{
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
}

.footer-link{
	display: flex;
	justify-content: space-between;
	width: 270px;
	margin-left: 45px;

	a{
		color: white;
		font-size: 13px;
		text-decoration: none;
	}
}

.footer-right {
	width: 600px;
	text-align: right;
}

@media (max-width: 785px){
	.footer-icon-link {
		margin: 15px auto 0;
	}

	.footer-bottom {
		height: 450px !important;
	}

	.footer-address{
		flex-direction: column-reverse;
		width: 270px;
		margin: 20px auto;
		text-align: center;
	}

	.footer-link{
		display: flex;
		justify-content: center;
		width: auto;
		flex-direction: row-reverse;
		margin-left: 0;
		margin-bottom: 10px;
	}

	.footer-link a:first-child{
		margin-left: 20px;
	}

	.footer-right {
		width: auto;
		text-align: center;
	}
}

@media (max-width: 500px){
	.footer-bottom{
		height: 580px !important;
	}
}
