@import "../ext/mq";

.catalogList {
	width: calc(100% + 40px);
	margin-left: -20px;

	&:after {
		content: '';
		display: inline-block;
		width: 100%
	}
	//background: rgba(red, .1);
	margin-top: 80px;
}

.catalogList-item {
	width: 50%;
	text-align: center;
	margin-bottom: 48px;
	float: left;
	padding-top: 20px;

	@include mq($until: tablet) {
		width: 100%;
	}

	.btn1, .btn2, .btn3 {
		margin: 0 5px 5px;
	}
}

.catalogList-card {
	display: block;
	text-decoration: none;
	color: #262626;
	text-align: center;
	margin: 0 20px;
}

.catalogList-date {
	display: block;
}

.catalogList-img {
	display: block;
	background: #000;


	img {
		display: block;
		width: 100%;
		opacity: .9;
		transition: .13s ease-out;
	}
}

.catalogList-card:hover  .catalogList-img  img {
	opacity: 1;
}

.catalogList-content {
	display: block;

}

.catalogList-type {
	padding: 12px 0 0;
	display: block;
	font: {
		family: ProximaNova;
		weight: 300;
		size: 15px;
	}
	color: #a5a5a5;

}

.catalogList-name {
	display: block;
	padding: 14px 0 7px;
	font: {
		family: ProximaNova;
		weight: 600;
		size: 22px;
	}
	text-transform: uppercase;
}

.catalogList-text {
	display: block;
	padding: 2px 0 23px;
	line-height: 1.3;
	font: {
		family: ProximaNova;
		weight: 300;
		size: 15px;
	}

	p {
		&:first-child {margin-top: 0;}
		&:last-child {margin-bottom: 0;}
	}
}

.catalogList-filter {
	text-align: center;
	padding: 30px 0 53px;

	a {
		display: inline-block;
		height: 43px;
		padding: 0 30px;
		min-width: 170px;
		box-sizing: border-box;
		background: #fff;
		color: #999999;
		text-decoration: none;
		line-height: 43px;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid #fff;
		font: {
			size: 16px;
			weight: 300;
		}
		transition: .2s ease-out;

		@include mq($until: tablet) {
			font-size: 14px;
			line-height: normal;
			height: auto;
			padding: 0 5px;
			min-width: 0;
		}

		&:hover {
			@include mq($from: tablet) {
				border: 1px solid #262626;
			}
		}

		&._current {
			@include mq($from: tablet) {
				background: #262626;
				color: #fff;
			}
			@include mq($until: tablet) {
				color: #64c2c9;
			}
		}
	}
}

.catalogList-filter + .catalogList  {
	margin-top: 0;
}
