@import "../ext/mq";

.pageTop {
	height: 610px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mq($until: tablet) {
		min-height: 100px;
		height: auto;
		padding: 20px 0;
	}

	img {
		min-width: 100%;
		height: 100% !important;
		width: auto !important;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		object-fit: cover;
	}

	.btn1, .btn2, .btn3 {
		margin: 0 10px 5px 0;
	}
}

.pageTop_darken {
	background: #000;
	img {
		opacity: .8;
	}
}

.pageTop_mobile {
	@include mq($until: tablet) {
		display: block;
		padding: 0;

		img {
			position: static;
			transform: none;
			display: block;
			width: 100% !important;
			height: auto !important;
		}

		.pageTop-contents {
			width: 100% !important;
			padding: 5% 5% !important;
			background: #6cafb7;
		}
	}
}

.pageTop-contents {
	position: relative;
	max-width: 1200px;
	width: 90%;
}

.pageTop-title {
	margin: 0 0 0;
	line-height: 1.2;
	max-width: 500px;
	font: {
		family: ProximaNova;
		size: 50px;
	}
	text-transform: uppercase;

	@include mq($until: tablet) {
		font-size: 40px;
	}
}

.pageTop-subtitle {
	font: {
		size: 30px;
	}
	text-transform: uppercase;
	line-height: 1.2;
	margin: 5px 0 0;
}

.pageTop-text {
	padding: 0px 0 43px;
	max-width: 440px;
	font: {
		family: ProximaNova;
		size: 18px;
		weight: 300;
	}
}
