@import "../sprite";

.folder2 {
	margin-left: 49px;
	margin-bottom: 40px;
}

.folder2-title {
	font: {
		weight: 600;
		size: 24px;
	}
	color: #333333;
	position: relative;
	text-decoration: none;
	display: inline-block;

	&:after {
		content: '';
		position: absolute;
		left: -49px;
		top: 0;
		@include sprite($plus2);
	}

}

.folder2-title_open {
	color: #64c2c9;
	&:after {
		@include sprite($minus2);
	}
}


.folder2-body {
	display: none;
}
