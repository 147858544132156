.list2, .list2 li {
	list-style: none;
	padding: 0;
	margin: 0;
	display: inline-block;
}


.list2 li {
	margin-left: 40px;

	&:first-child {
		margin-left: 0;
	}
}
