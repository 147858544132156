.order {
	padding: 0 40px;
	box-sizing: border-box;
	max-height: 100vh;
	overflow: auto;
}

.order-cert {
	width: auto;
	max-width: 580px;
	margin: 0 auto;
	padding-top: 95px;

	.certs-name {
		padding: 24px 0 25px;
	}
}

.order-form {
	max-width: 900px;
	padding: 48px 0 20px;
	border-top: 1px solid #64c2c9;
	margin: 47px auto 0;
}

