@import "../ext/mq";

.page {
	min-height: 100%;
	box-sizing: border-box;
	background: #fff;
	position: relative;
	z-index: 1;
	box-shadow: 0 0 10px rgba(#000, .5);
}

.page_sub {
	padding: 90px 0 90px;
	@include mq($until: tablet) {
		padding-top: 75px;
	}
}
