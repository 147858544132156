.responsiveVideo {
	position: relative;
	margin-top: 100px;

	&:before {
		content: '';
		position: absolute;
		left: -100%;
		right: -100%;
		top: 0;
		height: 100%;
		background: rgba(#262626, 1);
	}
}

.responsiveVideo-iframe {
	position: relative;
	width: 91.2%;
	margin: 0 auto -100px;
	top: -100px;

	&:after {
		content: '';
		display: block;
		padding-top: 56.25%;
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.responsiveVideo-text {
	position: relative;
	padding: 40px 0;
	color: #fff;
	font-size: 25px;
	text-align: center;
}
