@import "../ext/mq";

.reviews {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include mq($from: tablet) {
		margin-bottom: -30px;
	}
}

.reviews-item {
	width: 33.333%;
	//border: 1px solid red;
	padding: 0 .5%;
	margin: 0 0 80px;

	@include mq($until: tablet) {
		width: 100%;
		margin: 0 0 40px;
	}
}

.reviews-icon {
	color: #fff;
	font-size: 50px;
	font-weight: 600;
	background: #64c2c9;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 0 auto;
	line-height: 100px;

	@include mq($until: tablet) {
		display: none;
	}
}


.reviews-name {
	font: {
		size: 22px;
		weight: 600;
	}
	color: #262626;
	padding: 9px 20px 16px;
	line-height: 1.2;
}

.reviews-text {
	font-size: 15px;
	padding: 0 0 16px;
}

.reviews-date {
	font-size: 15px;
	color: #a5a5a5;
}
