@import "../ext/mq";

.equipment {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include mq($from: tablet) {
		margin-bottom: -50px;
	}
}

.equipment-item {
	width: 33.333%;
	padding: 0 .5%;
	margin: 0 0 110px;

	@include mq($until: tablet) {
		width: 100%;
		margin: 10px 0;
	}
	@include mq($from: mobile, $until: tablet) {
		text-align: left;
	}
	@include mq($until: mobile) {
		margin-bottom: 20px;
	}

	.btn1, .btn2, .btn3 {
		padding: 0;
		min-width: 144px;
		margin: 0 5px 5px;
	}

	img {
		width: 100%;

		@include mq($from: mobile, $until: tablet) {
			width: 30%;
			margin: 0 20px 20px 0;
			float: left;
		}
	}
}

.equipment-role {
	color: #a5a5a5;
	font-size: 15px;
}

.equipment-name {
	font: {
		size: 22px;
		weight: 600;
	}
	color: #262626;
	padding: 9px 20px 16px;
	line-height: 1.2;
}

.equipment-text {
	font-size: 15px;
	padding: 0 0 16px;
}
