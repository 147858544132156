@import "../ext/mq";

.top {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 90px;
	transition: .2s ease-out;
	z-index: 9000;

	@include mq($until: tablet) {
		height: 75px;
		background: rgba(#262626, 1);
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#262626, 0);
	}
}

.top_solid {
	&:before {
		background: rgba(#262626, 1);
	}
}

.top-content {
	width: 98%;
	height: 100%;
	max-width: 1720px;
	margin: 0 auto;
	display: table;
	align-items: center;
	flex-wrap: nowrap;
	position: relative;

	@include mq($until: desktop) {
		width: 100%;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
	}

	> div {
		display: table-cell;
		vertical-align: middle;

		@include mq($until: desktop) {
			display: none;
		}
	}
}

.top-logo {
	padding-right: 76px;

	@include mq($from: desktop) {
		width: 1%;
	}
	@include mq($until: desktop) {
		display: block !important;
	}
}

.top-menu {
}

.top-search {
	margin-right: 42px;
	position: relative;
	width: 1%;
	padding-right: 45px;
	padding-top: 8px;
}
.top-searchForm {
	position: absolute;
	right: 28px;
	top: 29px;
	box-shadow: 0 0 5px rgba(#000, .5);
}


.top-contacts {
	color: #fff;
	margin-top: -4px;
	width: 1%;
	white-space: nowrap;
	text-align: right;
}

.top-special {
	margin-right: 42px;
	position: relative;
	width: 1%;
	padding-right: 5px;
	padding-left: 20px;
	padding-top: 8px;
}


.top-address {
	color: #fff;
	text-decoration: none;
	font-size: 13px;
	white-space: nowrap;
}

.top-phone {
	font-size: 18px;
	display: inline-block;
	margin-left: 30px;
	white-space: nowrap;
}

.top-mobileMenu-ctrl {
	@include mq($from: desktop) {
		display: none !important;
	}
}

@media (max-width: 1320px) {
	.top-contacts {
		white-space: normal;
	}
}

.top-mailto{
	color: white;
	text-decoration: none;
	font-size: 18px;
	margin-left: 30px;
}

@media (max-width: 1024px) {
	.top-mailto {
		margin-left: 0;
		font-size: 16px;
		text-decoration: underline;
	}
}
