@import "../ext/mq";

.gallery {
	overflow: hidden;
	padding: 0 10px;

	a {
		display: block;
		float: left;
		box-sizing: border-box;
		width: 33.3333%;
		padding: 10px;

		@include mq($until: tablet) {
			width: 50%;
		}

		@include mq($until: mobile) {
			width: 100%;
		}

		img {
			display: block;
			width: 100%;
		}
	}
}
