.tb1 {
	border-collapse: collapse;
	margin: 0 auto 1rem;
	
	th, td {
		padding: 4px 8px;
		border: 1px solid #aaa;
		
		p {
			&:nth-child(1) {
				margin-top: 0;
			}
			
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}
	}
}

.tb1-gray {
	background: #808080;
	color: #fff;
}

.tb1-lightGray {
	background: #d9d9d9;
}