@import "ext/normalize.css";
@import "ext/bootstrap-grid.css";
@font-face {
  font-family: "OpenSans";
  src: url("/css/fonts/OpenSans-Regular-webfont.woff") format("woff"), url("/css/fonts/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/css/fonts/OpenSans-Bold-webfont.woff") format("woff"), url("/css/fonts/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"), url("/css/fonts/ProximaNova-Regular.woff") format("woff"), url("/css/fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"), url("/css/fonts/ProximaNova-Semibold.woff") format("woff"), url("/css/fonts/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"), url("/css/fonts/ProximaNova-Bold.woff") format("woff"), url("/css/fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: local("Proxima Nova Light"), local("ProximaNova-Light"), url("/css/fonts/ProximaNova-Light.woff") format("woff"), url("/css/fonts/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
html, body {
  font-family: ProximaNova;
}

* {
  outline: none;
}

b, strong, .bold {
  font-weight: 600;
}

a {
  color: #59a59d;
}

a[href^=tel] {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 50px;
}
@media (max-width: 35.99em) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
}
@media (max-width: 35.99em) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 600;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.s13 {
  font-size: 13px;
}

.s14 {
  font-size: 14px;
}

.s15 {
  font-size: 15px;
}

.s18 {
  font-size: 18px;
}

.s20 {
  font-size: 20px;
}

.s22 {
  font-size: 22px;
}

.s24 {
  font-size: 24px;
}

.s25 {
  font-size: 25px;
}

.s40 {
  font-size: 40px;
}

.normal {
  font-weight: 300;
}

.upper {
  text-transform: uppercase;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.lh-medium {
  line-height: 1.4;
}

.text-white {
  color: #fff;
}

.mw-100 {
  max-width: 100%;
}

.color1 {
  color: #64c2c9;
}

.color2 {
  color: #999999;
}

.div {
  display: block;
  position: relative;
  background: none;
  border: none;
  height: 70px;
  margin: 54px 0;
}
.div:before {
  position: absolute;
  left: 50%;
  top: 15px;
  content: "";
  width: 11px;
  height: 100%;
  background: #64c2c9;
  transform: skewX(-30deg) translateX(-50%);
}

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ProximaNova;
  font-weight: 300;
  font-size: 16px;
  color: #333;
  line-height: 1.35;
}

body._noScroll {
  overflow: hidden;
}

.w-100 {
  width: 100% !important;
}

.actionsTitle h1 {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
}

.actionsTitle h2 {
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
  color: #999999;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
.radio {
  margin-bottom: 20px;
  display: inline-block;
}

.radio-input {
  display: none;
}

.radio-tick {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border: 1px solid #b7b7b7;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 5px;
}
.radio-tick:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #b7b7b7;
  transform: translateY(-20%);
  opacity: 0;
  transition: 0.05s ease-out;
}

.radio-input:checked + .radio-tick:after {
  transform: translateY(0);
  opacity: 1;
}

.radio-label {
  vertical-align: middle;
  font-size: 16px;
}

.checkbox {
  margin-bottom: 20px;
  display: block;
}

.checkbox-input {
  display: none;
}

.checkbox-tick {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 23px;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  margin-right: 5px;
}
.checkbox-tick:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 6px;
  transform: translateY(-20%);
  opacity: 0;
  transition: 0.05s ease-out;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -165px -137px;
  width: 17px;
  height: 11px;
}

.checkbox-input:checked + .checkbox-tick:after {
  transform: translateY(0);
  opacity: 1;
}

.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.input {
  margin-bottom: 20px;
  display: block;
}

.input-label {
  display: block;
  font-size: 16px;
  padding-bottom: 6px;
}

.input-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #b7b7b7;
  appearance: none;
  height: 46px;
  padding: 0 16px;
}

.input-input._error, .input .input-input_error, .popupForm-error .input-input, .popupForm-error .popupForm-shakeable {
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.9);
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: 3;
}

.site {
  overflow: hidden;
}

.slashDecor {
  padding-left: 115px;
  position: relative;
}
.slashDecor:before {
  position: absolute;
  left: 47px;
  top: 5px;
  content: "";
  width: 11px;
  height: 70px;
  background: #64c2c9;
  transform: skewX(-30deg);
}

img.responsive {
  width: 100% !important;
  height: auto !important;
}

.btn1 {
  display: inline-block;
  height: 45px;
  padding: 0 55px;
  background: #64c2c9;
  color: #fff;
  text-decoration: none;
  line-height: 45px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  transition: 0.2s ease-out;
}
.btn1:hover {
  background: #47a0a7;
}
.btn1 + .btn2, .btn1 + .btn3 {
  margin-left: 10px;
}

.btn2 {
  display: inline-block;
  height: 43px;
  padding: 0 30px;
  min-width: 170px;
  box-sizing: border-box;
  background: #262626;
  color: #fff;
  text-decoration: none;
  line-height: 43px;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  transition: 0.2s ease-out;
}
.btn2:hover {
  background: #47a0a7;
}
.btn2 + .btn1, .btn2 + .btn3 {
  margin-left: 10px;
}

.text-white .btn2 {
  background: #fff;
  color: #262626;
}
.text-white .btn2:hover {
  background: #47a0a7;
  color: #fff;
}

.btn3 {
  display: inline-block;
  position: relative;
  top: 1px;
  height: 43px;
  padding: 0 30px;
  min-width: 170px;
  box-sizing: border-box;
  color: #262626;
  border: 1px solid #262626;
  background: rgba(0, 0, 0, 0);
  text-decoration: none;
  line-height: 43px;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  transition: 0.2s ease-out;
}
.btn3:hover {
  color: #47a0a7;
  border: 1px solid #47a0a7;
}
.btn3 + .btn1, .btn3 + .btn2 {
  margin-left: 10px;
}

.btn3_transparent {
  border: 1px solid #47a0a7;
  background: transparent;
  color: inherit;
}

.img-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
}

.img-link a {
  margin-left: 12px;
}

@media screen and (max-width: 768px) {
  .img-link {
    float: inherit;
  }
}
@media screen and (max-width: 768px) {
  .img-link img {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .img-link a {
    margin-left: 0;
  }
}
.btn3_back {
  padding-left: 51px;
}
.btn3_back:after {
  content: "";
  position: absolute;
  left: 17px;
  top: 50%;
  margin-top: -8.5px;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -165px -100px;
  width: 21px;
  height: 17px;
}

.text-white .btn3 {
  color: #fff;
  border-color: #fff;
}
.text-white .btn3:hover {
  color: #47a0a7;
  border-color: #47a0a7;
}

.social-wa {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -113px -52px;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.2s ease-out;
}
.social-wa:hover {
  opacity: 1;
}

.social-inst {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: 0px -61px;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.2s ease-out;
}
.social-inst:hover {
  opacity: 1;
}

.social-vk {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -113px 0px;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.2s ease-out;
}
.social-vk:hover {
  opacity: 1;
}

.social-fb {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -61px 0px;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.2s ease-out;
}
.social-fb:hover {
  opacity: 1;
}

.social-tg {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -52px -61px;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.2s ease-out;
}
.social-tg:hover {
  opacity: 1;
}

.icon-holder {
  position: relative;
}

@media (max-width: 49.115em) {
  .icon_float-left {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 10px;
  }
}
@media (min-width: 49.125em) {
  .icon_float-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-53px) translateY(-62%);
  }
}

.icon_app {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -110px -113px;
  width: 33px;
  height: 33px;
}

.icon_tick {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: 0px 0px;
  width: 59px;
  height: 59px;
}

.indexPage {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #585a5b;
}
@media (max-width: 35.99em) {
  .indexPage {
    height: auto;
  }
}

.indexPage-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.indexPage-bg div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.indexPage-content {
  width: 96%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  color: #fff;
}
@media (max-width: 35.99em) {
  .indexPage-content {
    height: auto;
    padding: 100px 0 80px;
    display: block;
  }
}
.indexPage-content h2 {
  font-size: 30px;
  margin-top: 0;
}
.indexPage-content p {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8125;
  margin-bottom: 0;
}
.indexPage-content a {
  text-decoration: none;
  color: inherit;
}

.indexPage-left, .indexPage-right {
  width: 50%;
  text-align: center;
  padding: 30px 0;
}
@media (max-width: 35.99em) {
  .indexPage-left, .indexPage-right {
    width: 100%;
  }
}

@media (min-width: 36em) {
  .indexPage-left {
    border-right: 1px solid #fff;
  }
}
@media (max-width: 35.99em) {
  .indexPage-left {
    position: relative;
  }
  .indexPage-left:after {
    content: "";
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 20%;
    right: 20%;
  }
}

.indexPage-bgRight {
  opacity: 0;
  transition: 0.2s ease-out;
}

.indexPage-bgRight_visible {
  opacity: 1;
}

.page {
  min-height: 100%;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.page_sub {
  padding: 90px 0 90px;
}
@media (max-width: 49.115em) {
  .page_sub {
    padding-top: 75px;
  }
}

.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  transition: 0.2s ease-out;
  z-index: 9000;
}
@media (max-width: 49.115em) {
  .top {
    height: 75px;
    background: #262626;
  }
}
.top:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0);
}

.top_solid:before {
  background: #262626;
}

.top-content {
  width: 98%;
  height: 100%;
  max-width: 1720px;
  margin: 0 auto;
  display: table;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
@media (max-width: 63.99em) {
  .top-content {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
}
.top-content > div {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 63.99em) {
  .top-content > div {
    display: none;
  }
}

.top-logo {
  padding-right: 76px;
}
@media (min-width: 64em) {
  .top-logo {
    width: 1%;
  }
}
@media (max-width: 63.99em) {
  .top-logo {
    display: block !important;
  }
}

.top-search {
  margin-right: 42px;
  position: relative;
  width: 1%;
  padding-right: 45px;
  padding-top: 8px;
}

.top-searchForm {
  position: absolute;
  right: 28px;
  top: 29px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.top-contacts {
  color: #fff;
  margin-top: -4px;
  width: 1%;
  white-space: nowrap;
  text-align: right;
}

.top-special {
  margin-right: 42px;
  position: relative;
  width: 1%;
  padding-right: 5px;
  padding-left: 20px;
  padding-top: 8px;
}

.top-address {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  white-space: nowrap;
}

.top-phone {
  font-size: 18px;
  display: inline-block;
  margin-left: 30px;
  white-space: nowrap;
}

@media (min-width: 64em) {
  .top-mobileMenu-ctrl {
    display: none !important;
  }
}

@media (max-width: 1320px) {
  .top-contacts {
    white-space: normal;
  }
}
.top-mailto {
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin-left: 30px;
}

@media (max-width: 1024px) {
  .top-mailto {
    margin-left: 0;
    font-size: 16px;
    text-decoration: underline;
  }
}
.mainMenu-item {
  display: inline-block;
  padding-right: 40px;
  white-space: nowrap;
}

.mainMenu-link {
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  transition: 0.15s ease-out;
}
.mainMenu-link:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
  width: 60px;
  max-width: 90%;
  height: 2px;
  background: rgba(100, 194, 201, 0);
  pointer-events: none;
  transition: 0.15s ease-out;
}

.mainMenu-link_current {
  color: #64c2c9;
}
.mainMenu-link_current:after {
  background: #64c2c9;
}

@media (max-width: 1150px) {
  .mainMenu-link {
    font-size: 14px;
  }
  .mainMenu-link:after {
    display: none;
  }
}
@media (min-width: 49.125em) {
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 49.115em) {
  .footer-fake {
    display: none;
  }
}

.footer-top {
  background: #f4f4f4;
  padding: 53px 0;
}

.footer-content {
  width: 98%;
  max-width: 1720px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.footer-menu {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  width: 62%;
  border-right: 1px solid #d0d0d0;
  box-sizing: border-box;
}
@media (max-width: 49.115em) {
  .footer-menu {
    display: none;
  }
}

.footer-menuItem {
  flex: 1;
}
.footer-menuItem:first-child {
  flex: 1.35;
}

.footer-form {
  display: block;
  width: 38%;
}
@media (max-width: 49.115em) {
  .footer-form {
    width: 100%;
    margin: 0 auto;
  }
}

.footer-bottom {
  background: #262626;
  height: 280px;
  color: #999;
  font-size: 12px;
  display: flex;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
@media (max-width: 49.115em) {
  .footer-bottom {
    height: auto;
    padding: 20px 0;
  }
}
@media screen and (max-width: 1199px) {
  .footer-bottom {
    height: 300px;
  }
}
@media screen and (max-width: 840px) {
  .footer-bottom {
    height: 450px;
  }
}
@media (max-width: 49.115em) {
  .footer-bottom .footer-content {
    display: block;
  }
}

.footer-left {
  width: 33.333%;
}
@media (max-width: 49.115em) {
  .footer-left {
    width: 100%;
    text-align: center;
  }
}

.footer-logo img {
  max-width: 135px;
}

.footer-copy {
  padding-top: 10px;
}

.footer-center {
  width: 33.333%;
  text-align: center;
}
@media (max-width: 49.115em) {
  .footer-center {
    width: 100%;
    margin: 20px 0;
  }
}
.footer-center a {
  margin: 0 7px;
}

.footer-idea {
  position: relative;
}
.footer-idea a {
  color: inherit;
}
.footer-idea p {
  font-size: 15px;
}
.footer-idea p span {
  display: inline-block;
}

.footer-icon-link {
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footer-icon-link {
    margin: 10px auto 0;
  }
}

.footer-idea-left {
  margin-top: 5px;
}

.footer-idea p:first-child {
  margin-top: 0;
}

.footer-address {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.footer-link {
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-left: 45px;
}
.footer-link a {
  color: white;
  font-size: 13px;
  text-decoration: none;
}

.footer-right {
  width: 600px;
  text-align: right;
}

@media (max-width: 785px) {
  .footer-icon-link {
    margin: 15px auto 0;
  }
  .footer-bottom {
    height: 450px !important;
  }
  .footer-address {
    flex-direction: column-reverse;
    width: 270px;
    margin: 20px auto;
    text-align: center;
  }
  .footer-link {
    display: flex;
    justify-content: center;
    width: auto;
    flex-direction: row-reverse;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .footer-link a:first-child {
    margin-left: 20px;
  }
  .footer-right {
    width: auto;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .footer-bottom {
    height: 580px !important;
  }
}
.secondMenu-link {
  text-decoration: none;
  color: #000;
  font-size: 13px;
  line-height: 2.4;
}

.secondMenu-item:first-child .secondMenu-link {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.crumbs {
  display: block;
  margin: 0;
  list-style: none;
  padding: 43px 0 74px;
  font-weight: 300;
  font-size: 16px;
  color: #999999;
}
@media (max-width: 49.115em) {
  .crumbs {
    font-size: 15px;
  }
}
.crumbs li {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.crumbs a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}
.crumbs a:last-child {
  color: #59a59d;
}

.subscriptionForm {
  text-align: center;
}

.subscriptionForm-title {
  font-weight: bold;
  font-size: 16px;
}

.subscriptionForm-input {
  appearance: none;
  display: block;
  margin: 0 auto;
  padding: 0 12px;
  box-sizing: border-box;
  height: 40px;
  width: 90%;
  max-width: 400px;
  border: 1px solid #b7b7b7;
}

.subscriptionForm-text {
  color: #8c8c8c;
  font-size: 12px;
  padding-top: 30px;
}

.contents {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.steps {
  overflow: hidden;
  position: relative;
  font-size: 18px;
  margin-top: 36px;
  padding-bottom: 40px;
}
.steps:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  left: 10%;
  top: 0;
  background: #64c2c9;
}

.steps-btn {
  position: absolute;
  left: 37px;
  bottom: 0;
}

.step, .step-left, .step-right {
  width: 90%;
  float: left;
  clear: both;
  box-sizing: border-box;
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.step-left {
  padding-right: 80px;
}
.step-left .step-num {
  right: -30px;
}

.step, .step-right {
  padding-left: 80px;
  float: right;
}
.step .step-num, .step-right .step-num {
  left: -30px;
}

.step-num {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  background: #64c2c9;
  text-align: center;
  line-height: 60px;
}

.price {
  max-width: 768px;
  margin: 12px auto 30px;
  overflow: auto;
}

.price-row {
  overflow: hidden;
  position: relative;
  padding: 0 0 9px;
}
.price-row:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}

.price-cell {
  float: left;
  font-size: 20px;
  position: relative;
  background: #fff;
}
@media (max-width: 49.115em) {
  .price-cell {
    font-size: 18px;
  }
}
.price-cell:nth-of-type(1) {
  font-size: 18px;
  padding-right: 6px;
}
@media (max-width: 49.115em) {
  .price-cell:nth-of-type(1) {
    font-size: 16px;
  }
}
.price-cell:nth-of-type(2) {
  float: right;
  padding-left: 6px;
}

.price-header {
  padding: 0 0 28px;
}
.price-header .price-cell:nth-of-type(1) {
  font-size: 20px;
}
@media (max-width: 49.115em) {
  .price-header .price-cell:nth-of-type(1) {
    font-size: 18px;
  }
}
.price-header .price-cell:nth-of-type(1) {
  font-weight: 600;
}
.price-header:before {
  display: none;
}

.pageTop {
  height: 610px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 49.115em) {
  .pageTop {
    min-height: 100px;
    height: auto;
    padding: 20px 0;
  }
}
.pageTop img {
  min-width: 100%;
  height: 100% !important;
  width: auto !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}
.pageTop .btn1, .pageTop .btn2, .pageTop .btn3 {
  margin: 0 10px 5px 0;
}

.pageTop_darken {
  background: #000;
}
.pageTop_darken img {
  opacity: 0.8;
}

@media (max-width: 49.115em) {
  .pageTop_mobile {
    display: block;
    padding: 0;
  }
  .pageTop_mobile img {
    position: static;
    transform: none;
    display: block;
    width: 100% !important;
    height: auto !important;
  }
  .pageTop_mobile .pageTop-contents {
    width: 100% !important;
    padding: 5% 5% !important;
    background: #6cafb7;
  }
}

.pageTop-contents {
  position: relative;
  max-width: 1200px;
  width: 90%;
}

.pageTop-title {
  margin: 0 0 0;
  line-height: 1.2;
  max-width: 500px;
  font-family: ProximaNova;
  font-size: 50px;
  text-transform: uppercase;
}
@media (max-width: 49.115em) {
  .pageTop-title {
    font-size: 40px;
  }
}

.pageTop-subtitle {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.2;
  margin: 5px 0 0;
}

.pageTop-text {
  padding: 0px 0 43px;
  max-width: 440px;
  font-family: ProximaNova;
  font-size: 18px;
  font-weight: 300;
}

.catalogList {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: 80px;
}
.catalogList:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.catalogList-item {
  width: 50%;
  text-align: center;
  margin-bottom: 48px;
  float: left;
  padding-top: 20px;
}
@media (max-width: 49.115em) {
  .catalogList-item {
    width: 100%;
  }
}
.catalogList-item .btn1, .catalogList-item .btn2, .catalogList-item .btn3 {
  margin: 0 5px 5px;
}

.catalogList-card {
  display: block;
  text-decoration: none;
  color: #262626;
  text-align: center;
  margin: 0 20px;
}

.catalogList-date {
  display: block;
}

.catalogList-img {
  display: block;
  background: #000;
}
.catalogList-img img {
  display: block;
  width: 100%;
  opacity: 0.9;
  transition: 0.13s ease-out;
}

.catalogList-card:hover .catalogList-img img {
  opacity: 1;
}

.catalogList-content {
  display: block;
}

.catalogList-type {
  padding: 12px 0 0;
  display: block;
  font-family: ProximaNova;
  font-weight: 300;
  font-size: 15px;
  color: #a5a5a5;
}

.catalogList-name {
  display: block;
  padding: 14px 0 7px;
  font-family: ProximaNova;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
}

.catalogList-text {
  display: block;
  padding: 2px 0 23px;
  line-height: 1.3;
  font-family: ProximaNova;
  font-weight: 300;
  font-size: 15px;
}
.catalogList-text p:first-child {
  margin-top: 0;
}
.catalogList-text p:last-child {
  margin-bottom: 0;
}

.catalogList-filter {
  text-align: center;
  padding: 30px 0 53px;
}
.catalogList-filter a {
  display: inline-block;
  height: 43px;
  padding: 0 30px;
  min-width: 170px;
  box-sizing: border-box;
  background: #fff;
  color: #999999;
  text-decoration: none;
  line-height: 43px;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #fff;
  font-size: 16px;
  font-weight: 300;
  transition: 0.2s ease-out;
}
@media (max-width: 49.115em) {
  .catalogList-filter a {
    font-size: 14px;
    line-height: normal;
    height: auto;
    padding: 0 5px;
    min-width: 0;
  }
}
@media (min-width: 49.125em) {
  .catalogList-filter a:hover {
    border: 1px solid #262626;
  }
}
@media (min-width: 49.125em) {
  .catalogList-filter a._current {
    background: #262626;
    color: #fff;
  }
}
@media (max-width: 49.115em) {
  .catalogList-filter a._current {
    color: #64c2c9;
  }
}

.catalogList-filter + .catalogList {
  margin-top: 0;
}

.catalogItem .btn1, .catalogItem .btn2, .catalogItem .btn3 {
  margin: 0 10px 5px 0;
}

.responsiveVideo {
  position: relative;
  margin-top: 100px;
}
.responsiveVideo:before {
  content: "";
  position: absolute;
  left: -100%;
  right: -100%;
  top: 0;
  height: 100%;
  background: #262626;
}

.responsiveVideo-iframe {
  position: relative;
  width: 91.2%;
  margin: 0 auto -100px;
  top: -100px;
}
.responsiveVideo-iframe:after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.responsiveVideo-iframe iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.responsiveVideo-text {
  position: relative;
  padding: 40px 0;
  color: #fff;
  font-size: 25px;
  text-align: center;
}

.frame1, .frame1_wide {
  background: #f4f4f4;
  text-align: center;
  padding: 40px 0;
  margin: 40px 0;
}

.frame1_wide {
  position: relative;
  padding-top: 20px;
}
.frame1_wide:before, .frame1_wide:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  background: #f4f4f4;
  width: 1920px;
}
.frame1_wide:after {
  right: auto;
  left: 100%;
}

.actionsList {
  padding: 100px 0 50px;
}

.actionsList-item {
  display: flex;
  padding-bottom: 50px;
  flex-wrap: wrap;
}
.actionsList-item:nth-of-type(2n+1) .actionsList-content {
  padding-left: 5%;
}
.actionsList-item:nth-of-type(2n+2) .actionsList-content {
  padding-right: 5%;
}
@media (min-width: 36em) {
  .actionsList-item:nth-of-type(2n+2) .actionsList-content {
    order: -1;
  }
}

.actionsList-img {
  display: block;
  width: 58%;
  overflow: hidden;
}
@media (max-width: 49.115em) {
  .actionsList-img {
    width: 42%;
  }
}
@media (max-width: 35.99em) {
  .actionsList-img {
    width: 100%;
  }
}
.actionsList-img img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.actionsList-content {
  display: flex;
  width: 42%;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 49.115em) {
  .actionsList-content {
    width: 58%;
  }
}
@media (max-width: 35.99em) {
  .actionsList-content {
    width: 100%;
  }
}

.actionsList-dates,
p.actionsList-dates {
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
}

.actionsList-name {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 5px 0 26px;
}

.actionsList-text {
  font-size: 15px;
  margin-bottom: 47px;
}
.actionsList-text p:first-child {
  margin-top: 0;
}
.actionsList-text p:last-child {
  margin-bottom: 0;
}

.actionsList-btns .btn1, .actionsList-btns .btn2, .actionsList-btns .btn3 {
  margin: 0 10px 5px 0;
}

.actionsItem {
  margin: 80px 0 100px;
}

.actionsItem-btn {
  margin-top: 100px;
}

.popup {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  z-index: 10000000000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.popup_visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.popup-box {
  width: 90%;
  max-width: 1000px;
  background: #fff;
  position: relative;
  max-height: 100%;
}
@media (max-width: 49.115em) {
  .popup-box {
    width: 100%;
  }
}

.popup-close {
  position: absolute;
  right: -33px;
  top: 0;
  z-index: 20;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -75px -113px;
  width: 33px;
  height: 33px;
}
@media (max-width: 49.115em) {
  .popup-close {
    right: 0;
  }
}

.popupForm {
  padding: 10px;
  position: relative;
}
.popupForm input {
  pointer-events: none;
}

.popupForm_noPadding {
  padding: 0;
}

.popup .popupForm {
  overflow: auto;
  max-height: 100vh;
}

.popupForm_busy:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8) url("/img/loader.gif") center center no-repeat;
}

.popupForm-title {
  max-width: 500px;
  margin: 40px auto 6px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.popupForm-text {
  max-width: 500px;
  margin: 13px auto 55px;
  text-align: center;
  font-size: 18px;
}

.popupForm-title_wide,
.popupForm-text_wide {
  max-width: 620px;
}

.popupForm-field {
  display: block;
  max-width: 440px;
  margin: 30px auto 0;
  position: relative;
}

div.popupForm-field {
  text-align: center;
  padding-top: 11px;
}

.popupForm-label {
  position: absolute;
  left: -12px;
  top: 12px;
  transform: translateX(-100%);
  color: #999999;
  font-size: 16px;
}
@media (max-width: 49.115em) {
  .popupForm-label {
    position: static;
  }
}

.popupForm-input {
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  border: 1px solid #b7b7b7;
  height: 46px;
  padding: 0 8px;
}

.popupForm-urgent .popupForm-label:after,
.popupForm-urgent .input-label:after {
  content: " *";
}

.popupForm-error .popupForm-input {
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.9);
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: 3;
}

.popupForm-state_success {
  display: none;
  padding: 20px 0 60px;
}
.popupForm-state_success i {
  margin-bottom: 10px;
}
.popupForm-state_success .popupForm-title {
  font-weight: 300;
  line-height: 1.6;
  text-transform: none;
}

.popupForm-footer {
  font-size: 13px;
  color: #999999;
  text-align: center;
  margin: 51px 0 18px;
}
.popupForm-footer a {
  color: inherit;
}

.searchForm {
  opacity: 0;
  transform: translateY(-100%);
  transition: 0.2s ease-out;
  display: flex;
}

.searchForm_visible {
  opacity: 1;
  transform: translateY(0);
}

.searchForm-ctrl {
  display: inline-block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -165px -119px;
  width: 16px;
  height: 16px;
}

.searchForm-close {
  display: block;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -75px -113px;
  width: 33px;
  height: 33px;
  background-color: #e0e0e0;
}

.searchForm-input {
  width: 287px;
  height: 33px;
  box-sizing: border-box;
  border: 0;
  display: block;
  appearance: none;
  font-size: 12px;
  padding: 0 12px;
}

.gallery {
  overflow: hidden;
  padding: 0 10px;
}
.gallery a {
  display: block;
  float: left;
  box-sizing: border-box;
  width: 33.3333%;
  padding: 10px;
}
@media (max-width: 49.115em) {
  .gallery a {
    width: 50%;
  }
}
@media (max-width: 35.99em) {
  .gallery a {
    width: 100%;
  }
}
.gallery a img {
  display: block;
  width: 100%;
}

.giftCard {
  margin: 78px 0 100px;
  position: relative;
}
.giftCard:before {
  content: "";
  position: absolute;
  left: -100%;
  right: -100%;
  bottom: 0;
  background: #262626;
  height: 50%;
}
@media (max-width: 49.115em) {
  .giftCard {
    padding: 20px 0;
  }
  .giftCard:before {
    height: 100%;
  }
}

.giftCard-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.giftCard-img {
  display: inline-block;
  vertical-align: bottom;
  width: calc(50% - 4px);
  position: relative;
  transform: translateX(40px);
}
@media (max-width: 49.115em) {
  .giftCard-img {
    display: none;
  }
}

.giftCard-text {
  display: inline-block;
  vertical-align: bottom;
  width: calc(50% - 4px);
  padding: 0 12% 0 8%;
  box-sizing: border-box;
}
@media (max-width: 49.115em) {
  .giftCard-text {
    width: 100%;
  }
}
.giftCard-text h2 {
  font-family: ProximaNova;
  font-weight: 300;
  font-size: 45px;
  margin-bottom: 96px;
  line-height: 1;
}
@media (max-width: 49.115em) {
  .giftCard-text h2 {
    color: #fff;
    margin-bottom: auto;
    font-size: 30px;
  }
}
.giftCard-text p {
  color: #fff;
  margin: 28px 0;
}

.page_sub .giftCard:last-child {
  margin-bottom: -90px;
}

.subMenu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.subMenu li {
  padding: 0;
  margin: 0 0 2px 0;
  list-style: none;
}
.subMenu li a {
  display: block;
  position: relative;
  background: #ebebeb;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  padding: 11px 45px 9px;
  text-decoration: none;
}
.subMenu li a:after {
  content: "";
  position: absolute;
  left: 45px;
  right: 45px;
  bottom: 11px;
  border-bottom: 1px dashed #000;
}
.subMenu li.subMenu-currentItem a {
  background: #64c2c9;
  color: #fff;
}
.subMenu li.subMenu-currentItem a:after {
  display: none;
}
.subMenu li:hover a:after {
  display: none;
}

.staff {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 49.125em) {
  .staff {
    margin-bottom: -50px;
  }
}

.staff-item {
  width: 25%;
  padding: 0 4%;
  margin: 0 0 110px;
}
@media (max-width: 49.115em) {
  .staff-item {
    width: 100% !important;
    margin: 10px 0;
  }
}
@media (min-width: 36em) and (max-width: 49.115em) {
  .staff-item {
    text-align: left;
  }
}
@media (max-width: 35.99em) {
  .staff-item {
    margin-bottom: 20px;
  }
}
.staff-item .btn1, .staff-item .btn2, .staff-item .btn3 {
  padding: 0;
  min-width: 144px;
  margin: 0 5px 5px;
}
.staff-item img {
  width: 100% !important;
  height: auto !important;
}
@media (min-width: 36em) and (max-width: 49.115em) {
  .staff-item img {
    width: 30%;
    margin: 0 20px 20px 0;
    float: left;
  }
}

.staff-name {
  font-size: 22px;
  font-weight: 600;
  color: #262626;
  padding: 9px 20px 16px;
  line-height: 1.2;
}

.staff-role {
  font-size: 15px;
  padding: 0 0 16px;
}

.staff-popup {
  display: none;
  padding: 95px;
  overflow: auto;
  max-height: 100vh;
}
@media (max-width: 49.115em) {
  .staff-popup {
    padding: 20px 30px;
  }
}
.staff-popup hr {
  border: none;
  background: #e0e0e0;
  height: 1px;
  margin: 30px 0;
}
.staff-popup p:last-child {
  margin-bottom: 100px;
}
.staff-popup .staff-header {
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}
@media (max-width: 35.99em) {
  .staff-popup .staff-header {
    display: block;
  }
  .staff-popup .staff-header img {
    width: 100%;
  }
}
.staff-popup .staff-header .staff-name {
  font-size: 30px;
  padding-left: 75px;
}
@media (max-width: 35.99em) {
  .staff-popup .staff-header .staff-name {
    padding-left: 0;
  }
}
.staff-popup .staff-header .staff-role {
  font-size: 18px;
  padding-left: 75px;
}
@media (max-width: 35.99em) {
  .staff-popup .staff-header .staff-role {
    padding-left: 0;
  }
}

.staff-popup_visible {
  display: block;
}

.equipment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 49.125em) {
  .equipment {
    margin-bottom: -50px;
  }
}

.equipment-item {
  width: 33.333%;
  padding: 0 0.5%;
  margin: 0 0 110px;
}
@media (max-width: 49.115em) {
  .equipment-item {
    width: 100%;
    margin: 10px 0;
  }
}
@media (min-width: 36em) and (max-width: 49.115em) {
  .equipment-item {
    text-align: left;
  }
}
@media (max-width: 35.99em) {
  .equipment-item {
    margin-bottom: 20px;
  }
}
.equipment-item .btn1, .equipment-item .btn2, .equipment-item .btn3 {
  padding: 0;
  min-width: 144px;
  margin: 0 5px 5px;
}
.equipment-item img {
  width: 100%;
}
@media (min-width: 36em) and (max-width: 49.115em) {
  .equipment-item img {
    width: 30%;
    margin: 0 20px 20px 0;
    float: left;
  }
}

.equipment-role {
  color: #a5a5a5;
  font-size: 15px;
}

.equipment-name {
  font-size: 22px;
  font-weight: 600;
  color: #262626;
  padding: 9px 20px 16px;
  line-height: 1.2;
}

.equipment-text {
  font-size: 15px;
  padding: 0 0 16px;
}

.folder-body {
  display: none;
}

.folder-less {
  display: none;
}

.reviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 49.125em) {
  .reviews {
    margin-bottom: -30px;
  }
}

.reviews-item {
  width: 33.333%;
  padding: 0 0.5%;
  margin: 0 0 80px;
}
@media (max-width: 49.115em) {
  .reviews-item {
    width: 100%;
    margin: 0 0 40px;
  }
}

.reviews-icon {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  background: #64c2c9;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 100px;
}
@media (max-width: 49.115em) {
  .reviews-icon {
    display: none;
  }
}

.reviews-name {
  font-size: 22px;
  font-weight: 600;
  color: #262626;
  padding: 9px 20px 16px;
  line-height: 1.2;
}

.reviews-text {
  font-size: 15px;
  padding: 0 0 16px;
}

.reviews-date {
  font-size: 15px;
  color: #a5a5a5;
}

#map {
  height: 600px;
}

.list1 {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.list1 li {
  display: block;
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  list-style: none;
  position: relative;
}
.list1 li:last-child {
  margin-bottom: 0;
}
.list1 li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  width: 10px;
  height: 2px;
  background: #7fcbd1;
}

.certs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.certs-item {
  width: 48.42%;
  text-align: center;
  margin-bottom: 35px;
}
@media (max-width: 49.115em) {
  .certs-item {
    width: 100%;
  }
}
.certs-item img {
  width: 100%;
}

.certs-type {
  font-size: 15px;
  color: #a5a5a5;
  padding: 14px 0 6px;
}

.certs-name {
  font-size: 22px;
  color: #262626;
  font-weight: 600;
  text-transform: uppercase;
}

.certs-text {
  font-size: 15px;
  padding: 17px 0 20px;
}

.certs-price, .certs-quantity, .certs-btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0 14px 20px;
}

.certs-price {
  font-size: 18px;
  font-weight: 600;
}

.certs-total {
  font-size: 22px;
}

.quantity {
  display: inline-block;
  overflow: hidden;
  border-top: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
}

.quantity-input {
  display: block;
  float: left;
  border: none;
  appearance: none;
  width: 49px;
  height: 35px;
  text-align: center;
}

.quantity-minus {
  display: block;
  float: left;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: 0px -113px;
  width: 36px;
  height: 35px;
}

.quantity-plus {
  display: block;
  float: left;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -38px -113px;
  width: 35px;
  height: 35px;
}

.kCompare {
  position: relative;
}

.kCompare-item {
  position: relative;
  overflow: hidden;
}
.kCompare-item img {
  width: 100% !important;
  height: auto !important;
  display: block;
  pointer-events: none;
}

.kCompare-slider {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.kCompare-slider .kCompare-item {
  height: 100%;
}
.kCompare-slider img {
  position: absolute;
  right: 0;
  top: 0;
  width: auto !important;
  height: 100% !important;
}

.kCompare-drag {
  position: absolute;
  cursor: pointer;
  left: -15px;
  top: 50%;
  width: 30px;
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  transform: translateY(-50%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.kCompare-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  z-index: 999;
}

.catalogMenu {
  background: #262626;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  padding-top: 90px;
  display: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.catalogMenu-contents {
  position: relative;
  padding: 66px 0 40px;
  display: flex;
}
.catalogMenu-contents:before, .catalogMenu-contents:after {
  content: "";
  position: absolute;
  left: 33.333%;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #f4f4f4;
}
.catalogMenu-contents:after {
  left: -100%;
  right: 66.666%;
  background: #e3e3e3;
}

.catalogMenu-left {
  position: relative;
  z-index: 1;
  width: 33.333%;
}

.catalogMenu-right {
  position: relative;
  z-index: 1;
  width: 66.666%;
  height: 100%;
  overflow: hidden;
  padding-left: 9%;
}

.catalogMenu-div {
  display: none;
}
.catalogMenu-div:first-child {
  display: block;
}

.catalogMenu-title {
  transition: 0.4s ease-out;
  padding-bottom: 28px;
}
.catalogMenu-title a {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
.catalogMenu-title a:hover, .catalogMenu-title a._current {
  color: #64c2c9;
}

.catalogMenu-item {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding-left: 4.8%;
  padding-bottom: 3px;
}
.catalogMenu-item a {
  position: relative;
  color: #000;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}
.catalogMenu-item a:hover {
  color: #64c2c9;
}

.catalogMenu_visible .catalogMenu-title,
.catalogMenu_visible .catalogMenu-item {
  transform: translateY(0);
}

.catalogMenu-tags {
  transform: translateX(-6px);
  white-space: nowrap;
  position: absolute;
  right: 100%;
  top: -3px;
}

.catalogMenu-tag {
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  height: 14px;
  line-height: 14px;
  padding: 0 3px;
  display: inline-block;
}
.catalogMenu-tag.catalogMenu-tag_new {
  background: #64c2c9;
}
.catalogMenu-tag.catalogMenu-tag_new:after {
  content: "new";
}
.catalogMenu-tag.catalogMenu-tag_percent {
  background: #3e8f8d;
}
.catalogMenu-tag.catalogMenu-tag_percent:after {
  content: "%";
}

.mobileMenu-ctrl {
  display: block;
  width: 26px;
  height: 19px;
  position: relative;
}
.mobileMenu-ctrl span {
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  transition: top 0.1s 0.1s ease-out, margin-top 0.1s 0.1s ease-out, transform 0.1s ease-out;
}
.mobileMenu-ctrl span:nth-of-type(2) {
  top: 50%;
}
.mobileMenu-ctrl span:nth-of-type(3) {
  top: 100%;
}

.mobileMenu-ctrl_open span {
  background: #64c2c9;
  transition: top 0.1s ease-out, margin-top 0.1s ease-out, transform 0.1s 0.1s ease-out;
}
.mobileMenu-ctrl_open span:nth-of-type(1) {
  top: 50%;
  margin-top: -1px;
  transform: rotate(45deg);
}
.mobileMenu-ctrl_open span:nth-of-type(2) {
  top: 50%;
  margin-top: -1px;
  opacity: 0;
}
.mobileMenu-ctrl_open span:nth-of-type(3) {
  top: 50%;
  margin-top: -1px;
  transform: rotate(-45deg);
}

.mobileMenu {
  position: fixed;
  z-index: 8999;
  left: 0;
  top: 0;
  bottom: 100%;
  right: 0;
  background: #262626;
  opacity: 0.7;
  visibility: hidden;
  transition: visibility 0s 0.2s, opacity 0.2s linear, bottom 0.2s ease-out;
}

.mobileMenu_visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  bottom: 0;
  transition: opacity 0.2s linear, bottom 0.2s ease-out;
}

.mobileMenu-content {
  padding: 100px 0 20px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileMenu-item {
  overflow: hidden;
  max-height: 43px;
  padding: 0 20px;
  transition: 0.3s ease-out;
}
.mobileMenu-item .secondMenu-link {
  color: #fff;
}
.mobileMenu-item.mobileMenu-item_visible {
  background: #171717;
  max-height: 400px;
  padding: 20px;
}

.mobileMenu-contacts {
  color: #fff;
  position: relative;
  top: -40px;
}
.mobileMenu-contacts a {
  margin-bottom: -15px;
  display: block;
}

.list2, .list2 li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.list2 li {
  margin-left: 40px;
}
.list2 li:first-child {
  margin-left: 0;
}

.folder2 {
  margin-left: 49px;
  margin-bottom: 40px;
}

.folder2-title {
  font-weight: 600;
  font-size: 24px;
  color: #333333;
  position: relative;
  text-decoration: none;
  display: inline-block;
}
.folder2-title:after {
  content: "";
  position: absolute;
  left: -49px;
  top: 0;
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -165px -35px;
  width: 33px;
  height: 33px;
}

.folder2-title_open {
  color: #64c2c9;
}
.folder2-title_open:after {
  background-image: url(../img/sprite.png?t1671190994407);
  background-position: -165px 0px;
  width: 33px;
  height: 33px;
}

.folder2-body {
  display: none;
}

.order {
  padding: 0 40px;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: auto;
}

.order-cert {
  width: auto;
  max-width: 580px;
  margin: 0 auto;
  padding-top: 95px;
}
.order-cert .certs-name {
  padding: 24px 0 25px;
}

.order-form {
  max-width: 900px;
  padding: 48px 0 20px;
  border-top: 1px solid #64c2c9;
  margin: 47px auto 0;
}

.bdBanner {
  color: #fff;
  text-align: center;
  font-size: 19px;
}
@media (min-width: 800px) {
  .bdBanner {
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 0;
    max-width: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
@media (max-width: 800px) {
  .bdBanner {
    display: block;
    text-align: center;
    background: #262626;
  }
}
.bdBanner span {
  display: inline-block;
  padding: 7px 21px 6px;
}
.bdBanner span:nth-of-type(1) {
  background: #64c2c9;
  font-weight: 600;
}
.bdBanner span:nth-of-type(2) {
  background: #006875;
}

.bdBanner_ny span:nth-of-type(1) {
  height: 50px;
  background: #39a7af url("/img/index/ny-banner.png") left center no-repeat;
  padding: 13px 60px 6px 190px;
}
@media (max-width: 800px) {
  .bdBanner_ny span:nth-of-type(1) {
    width: 100%;
    padding: 13px;
    text-align: center;
    background-position: -50px 50%;
  }
}
@media (max-width: 500px) {
  .bdBanner_ny span:nth-of-type(1) {
    background: #39a7af;
    font-size: 16px;
  }
}

.nyBanner {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.nyBanner span {
  display: inline-block;
  padding: 7px 21px 6px 191px;
  background: url("/img/ny.png") left center no-repeat #39a7af;
}
@media (min-width: 800px) {
  .nyBanner {
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 0;
    max-width: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
@media (max-width: 800px) {
  .nyBanner {
    display: block;
    text-align: center;
    background: #262626;
  }
  .nyBanner span {
    background: #39a7af;
    padding: 7px 21px 6px;
  }
}

@keyframes floatingBannerAnim {
  from {
    transform: translateX(200%) rotate(180deg);
  }
  to {
    transform: translateX(0) rotate(0);
  }
}
.floatingBanner {
  border: 3px solid #63c0c7;
  color: #fff;
  border-radius: 50%;
  position: fixed;
  right: 86px;
  top: 50%;
  z-index: 10;
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  margin-top: -60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 0.2s;
  background: rgba(99, 192, 199, 0.85);
  font-family: OpenSans, sans-serif;
  animation-name: "floatingBannerAnim";
  animation-duration: 1s;
}
.floatingBanner:hover {
  background: #63c0c7;
}

.floatingBanner-title {
  font-size: 18px;
  margin: -2px 0 0px;
}

.floatingBanner-dev {
  width: 1px;
  height: 12px;
  background: #fff;
  margin: 5px 0 7px;
}

.floatingBanner-subTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px;
}

.floatingBanner-description {
  font-size: 18px;
  margin: -4px 0 11px;
}

@media (max-width: 1750px) {
  .floatingBanner {
    right: 10px;
  }
}
@media (max-width: 800px) {
  .floatingBanner {
    border: none;
    border-bottom: 3px solid #63c0c7;
    top: 75px;
    left: 0;
    right: 0;
    animation: none;
    width: auto;
    height: auto;
    border-radius: 0;
    flex-direction: row;
    margin: 0;
    padding-top: 4px;
  }
  .floatingBanner-title {
    font-size: 21px;
    font-weight: bold;
    margin: 0;
    padding: 0 20px 0 0;
    text-transform: uppercase;
  }
  .floatingBanner-dev {
    display: none;
  }
  .floatingBanner-subTitle {
    margin: 0;
    padding: 0 5px 0 0;
    font-size: 18px;
  }
  .floatingBanner-description {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
}
.tb1 {
  border-collapse: collapse;
  margin: 0 auto 1rem;
}
.tb1 th, .tb1 td {
  padding: 4px 8px;
  border: 1px solid #aaa;
}
.tb1 th p:nth-child(1), .tb1 td p:nth-child(1) {
  margin-top: 0;
}
.tb1 th p:nth-last-child(1), .tb1 td p:nth-last-child(1) {
  margin-bottom: 0;
}

.tb1-gray {
  background: #808080;
  color: #fff;
}

.tb1-lightGray {
  background: #d9d9d9;
}

.youtube {
  position: relative;
}
.youtube:after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.happyBirthday {
  max-width: 1007px;
  max-height: 560px;
  width: 100vw;
  height: 100vh;
  background: url("/userfiles/img/popup-bg.jpg") left center no-repeat;
  display: flex;
  align-items: center;
}

.happyBirthday-content {
  max-width: 360px;
  width: 90%;
  margin-left: 11.7%;
  color: #fff;
}

.happyBirthday-header {
  font-family: ProximaNova, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.happyBirthday-text {
  font-family: ProximaNova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 64px;
}

.certPopup {
  background: url("/img/index/set-popup-bg.png") center center;
  background-size: cover;
  width: 1000px;
  height: 550px;
  max-width: 96vw;
  max-height: 96vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certPopup3 {
  background-size: cover;
  width: 100%;
  height: 400px;
  background: url(/img/index/ny2023-pop-up.webp) no-repeat right center;
}

@media (max-width: 576px) {
  .certPopup3 {
    height: 600px;
    background: url(/img/index/pop-up-mobile.webp) no-repeat center center;
  }
}
.certPopup-text {
  width: 394px;
  max-width: 90%;
  font-size: 30px;
}
@media (max-width: 600px) {
  .certPopup-text {
    font-size: 23px;
  }
  .certPopup-text .btn1 {
    padding: 0px 14px;
    font-size: 14px;
  }
}