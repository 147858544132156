@import "../ext/mq";

html, body {
	font-family: ProximaNova;
}

* {
	outline: none;
}

b, strong, .bold {
	font-weight: 600;
}

a {
	color: #59a59d;
}

a[href^="tel"] {
	text-decoration: none;
	color: inherit;
}

p {
	//margin: 12px 0;
}

h1 {
	font: {
		size: 50px;
	}

	@include mq($until: mobile) {
		font-size: 40px;
	}

}

h2 {
	font: {
		size: 40px;
	}

	@include mq($until: mobile) {
		font-size: 35px;
	}
}

h3 {
	font: {
		size: 22px;
		weight: 600;
	}
}



.shadow {
	box-shadow: 0 0 10px rgba(#000, .4);
}

.s13 {font-size: 13px;}
.s14 {font-size: 14px;}
.s15 {font-size: 15px;}
.s18 {font-size: 18px;}
.s20 {font-size: 20px;}
.s22 {font-size: 22px;}
.s24 {font-size: 24px;}
.s25 {font-size: 25px;}
.s40 {font-size: 40px;}

.normal {
	font-weight: 300;
}

.upper {
	text-transform: uppercase;
}

.center {
	text-align: center !important;
}
.left {
	text-align: left !important;
}
.right {
	text-align: right !important;
}

.lh-medium {
	line-height: 1.4;
}

.text-white {
	color: #fff;
}


.mw-100 {
	max-width: 100%;
}

.color1 {
	color: #64c2c9;
}

.color2 {
	color: #999999;
}

.div {
	display: block;
	position: relative;
	background: none;
	border: none;
	height: 70px;
	margin: 54px 0;

	&:before {
		position: absolute;
		left: 50%;
		top: 15px;
		content: '';
		width: 11px;
		height: 100%;
		background: #64c2c9;

		transform: skewX(-30deg) translateX(-50%);
	}
}
