.steps {
	overflow: hidden;
	position: relative;
	font-size: 18px;
	margin-top: 36px;
	padding-bottom: 40px;

	&:before {
		content: '';
		width: 1px;
		height: 100%;
		position: absolute;
		left: 10%;
		top: 0;
		background: #64c2c9;
	}
}

.steps-btn {
	position: absolute;
	left: 37px;
	bottom: 0;
}

.step, .step-left, .step-right {
	width: 90%;
	float: left;
	clear: both;
	box-sizing: border-box;
	position: relative;
	min-height: 60px;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.step-left {
	padding-right: 80px;
	.step-num {
		right: -30px;
	}
}

.step, .step-right {
	padding-left: 80px;
	float: right;
	.step-num {
		left: -30px;
	}
}

.step-num {
	position: absolute;
	top: 50%;
	margin-top: -30px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 30px;
	color: #fff;
	background: #64c2c9;
	text-align: center;
	line-height: 60px;
}
