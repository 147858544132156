@import "../ext/mq";

.crumbs {
	display: block;
	margin: 0;
	list-style: none;
	padding: 43px 0 74px;
	font: {
		weight: 300;
		size: 16px;
	}
	color: #999999;

	@include mq($until: tablet) {
		font-size: 15px;
	}

	li {
		display: inline-block;
		padding: 0;
		margin: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
		white-space: nowrap;

		&:last-child {
			color: #59a59d;
		}
	}
}
