.radio {
	margin-bottom: 20px;
	display: inline-block;
}

.radio-input {
	display: none;
}

.radio-tick {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	border: 1px solid  #b7b7b7;
	border-radius: 50%;
	box-sizing: border-box;
	margin-right: 5px;

	&:after {
		content: '';
		position: absolute;
		left: 5px;
		top: 5px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #b7b7b7;
		transform: translateY(-20%);
		opacity: 0;
		transition: .05s ease-out;
	}
}

.radio-input:checked + .radio-tick {
	&:after {
		transform: translateY(0);
		opacity: 1;
	}
}

.radio-label {
	//display: inline-block;
	vertical-align: middle;
	//color: #999999;
	font-size: 16px;
}
