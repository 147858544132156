@keyframes floatingBannerAnim {
	from {
		transform: translateX(200%) rotate(180deg);
	}

	to {
		transform: translateX(0) rotate(0);
	}
}

.floatingBanner {
	border: 3px solid #63c0c7;
	color: #fff;
	border-radius: 50%;
	position: fixed;
	right: 86px;
	top: 50%;
	z-index: 10;
	width: 180px;
	height: 180px;
	box-sizing: border-box;
	margin-top: -60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: .2s;
	background: rgba(#63c0c7, .85);
	font-family: OpenSans, sans-serif;
	
	animation: {
		name: 'floatingBannerAnim';
		duration: 1s;
	};
	
	&:hover {
		background: rgba(#63c0c7, 1);
	}
}

.floatingBanner-title {
	font-size: 18px;
	margin: -2px 0 0px;
}

.floatingBanner-dev {
	width: 1px;
	height: 12px;
	background: #fff;
	margin: 5px 0 7px;
}

.floatingBanner-subTitle {
	font-size: 20px;
	font-weight: bold;
	margin: 0 0 5px;
}

.floatingBanner-description {
	font-size: 18px;
	margin: -4px 0 11px;
}

@media (max-width: 1750px) {
	.floatingBanner {
		right: 10px;
	}
}

@media (max-width: 800px) {
	.floatingBanner {
		border: none;
		border-bottom: 3px solid #63c0c7;
		top: 75px;
		left: 0;
		right: 0;
		animation: none;
		width: auto;
		height: auto;
		border-radius: 0;
		flex-direction: row;
		margin: 0;
		padding-top: 4px;
	}

	.floatingBanner-title {
		font-size: 21px;
		font-weight: bold;
		margin: 0;
		padding: 0 20px 0 0 ;
		text-transform: uppercase;
	}
	.floatingBanner-dev {
		display: none;
	}
	.floatingBanner-subTitle {
		margin: 0;
		padding: 0 5px 0 0;
		font-size: 18px;
	}
	.floatingBanner-description {
		margin: 0;
		padding: 0;
		font-size: 18px;
	}
}