.kCompare {
	position: relative;
}

.kCompare-item {
	position: relative;
	overflow: hidden;

	img {
		width: 100% !important;
		height: auto !important;
		display: block;
		pointer-events: none;
	}
}

.kCompare-slider {
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 100%;
	border-left: 1px solid rgba(#fff, .5);

	.kCompare-item {
		height: 100%;
	}
	img {
		position: absolute;
		right: 0;
		top: 0;

		width: auto !important;
		height: 100%  !important;
	}
}

.kCompare-drag {
	position: absolute;
	cursor: pointer;
	left: -15px;
	top: 50%;
	width: 30px;
	height: 80px;
	background: rgba(#fff, .8);
	border-radius: 5px;
	transform: translateY(-50%);
	box-shadow: 0 0 3px rgba(#000, .2);
	z-index: 1000;
}

.kCompare-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: none;
	z-index: 999;
	//background: rgba(red, .1);
}
