@import "../sprite";

.quantity {
	display: inline-block;
	overflow: hidden;
	border: {
		top: 1px solid #b7b7b7;
		bottom: 1px solid #b7b7b7;
	}
}

.quantity-input {
	display: block;
	float: left;
	border: none;
	appearance: none;
	width: 49px;
	height: 35px;
	text-align: center;
}

.quantity-minus {
	display: block;
	float: left;
	@include sprite($minus);
}

.quantity-plus {
	display: block;
	float: left;
	@include sprite($plus);
}
