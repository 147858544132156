@import "../ext/mq";

.giftCard {
	margin: 78px 0 100px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: -100%;
		right: -100%;
		bottom: 0;
		background: #262626;
		height: 50%;
	}

	@include mq($until: tablet) {
		padding: 20px 0;
		&:before {
			height: 100%;
		}
	}
}

.giftCard-content {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

.giftCard-img {
	display: inline-block;
	vertical-align: bottom;
	width: calc(50% - 4px);
	position: relative;
	transform: translateX(40px);

	@include mq($until: tablet) {
		display: none;
	}
}

.giftCard-text {
	display: inline-block;
	vertical-align: bottom;
	width: calc(50% - 4px);
	padding: 0 12% 0 8%;
	box-sizing: border-box;

	@include mq($until: tablet) {
		width: 100%;
	}

	h2 {
		font: {
			family: ProximaNova;
			weight: 300;
			size: 45px;
		}
		margin-bottom: 96px;
		line-height: 1;

		@include mq($until: tablet) {
			color: #fff;
			margin-bottom: auto;
			font-size: 30px;
		}
	}

	p {
		color: #fff;
		margin: 28px 0;
	}
}

.page_sub {
	.giftCard:last-child {
		margin-bottom: -90px;
	}
}

