@import "../sprite";
@import "../ext/mq";

.social-wa {
	display: inline-block;
	@include sprite($social-wa);
	opacity: .7;
	transition: .2s ease-out;
	&:hover {
		opacity: 1;
	}
}

.social-inst {
	display: inline-block;
	@include sprite($social-inst);
	opacity: .7;
	transition: .2s ease-out;
	&:hover {
		opacity: 1;
	}
}

.social-vk {
	display: inline-block;
	@include sprite($social-vk);
	opacity: .7;
	transition: .2s ease-out;
	&:hover {
		opacity: 1;
	}
}

.social-fb {
	display: inline-block;
	@include sprite($social-fb);
	opacity: .7;
	transition: .2s ease-out;
	&:hover {
		opacity: 1;
	}
}

.social-tg {
	display: inline-block;
	@include sprite($social-tg);
	opacity: .7;
	transition: .2s ease-out;
	&:hover {
		opacity: 1;
	}
}


.icon {

}

.icon-holder {
	position: relative;
}

.icon_float-left {
	@include mq($until: tablet) {
		display: inline-block;
		vertical-align: baseline;
		margin-right: 10px;

	}
	@include mq($from: tablet) {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateX(-53px) translateY(-62%)
	}
}

.icon_app {
	display: inline-block;
	@include sprite($icon-app);
}

.icon_tick {
	display: inline-block;
	@include sprite($icon-tick);
}
