@import "../ext/mq";

.popupForm {
	padding: 10px;
	position: relative;

	input {
		pointer-events: none;
	}
}

.popupForm_noPadding {
	padding: 0;
}

.popup .popupForm {
	overflow: auto;
	max-height: 100vh;
}

.popupForm_busy {
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: rgba(#fff, .8) url('/img/loader.gif') center center no-repeat;
	}
}


.popupForm-title {
	max-width: 500px;
	margin: 40px auto 6px;
	text-align: center;
	font-size: 30px;
	font-weight: 600;
	text-transform: uppercase;
}

.popupForm-text {
	max-width: 500px;
	margin: 13px auto 55px;
	text-align: center;
	font-size: 18px;
}

.popupForm-title_wide,
.popupForm-text_wide {
	max-width: 620px;
}


.popupForm-field {
	display: block;
	max-width: 440px;
	margin: 30px auto 0;
	position: relative;
}

div.popupForm-field {
	text-align: center;
	padding-top: 11px;
}

.popupForm-label {
	position: absolute;
	left: -12px;
	top: 12px;
	transform: translateX(-100%);
	color: #999999;
	font-size: 16px;

	@include mq($until: tablet) {
		position: static;
	}

}

.popupForm-input {
	width: 100%;
	box-sizing: border-box;
	appearance: none;
	border: 1px solid #b7b7b7;
	height: 46px;
	padding: 0 8px;
}

.popupForm-urgent {
	.popupForm-label,
	.input-label {
		&:after {
			content: ' *'
		}
	}
}



.popupForm-error {
	.popupForm-input {
		box-shadow: 0 0 5px rgba(red, .9);
		animation: {
			name: shake;
			duration: .1s;
			iteration-count: 3;
		}
	}
}

.popupForm-state_success {
	display: none;
	padding: 20px 0 60px;
	i {
		margin-bottom: 10px;
	}
	.popupForm-title {
		font-weight: 300;
		line-height: 1.6;
		text-transform: none;
	}
}


.popupForm-footer {
	font-size: 13px;
	color: #999999;
	text-align: center;
	margin: 51px 0 18px;

	a {color: inherit}
}
