.youtube {
	position: relative;

	&:after {
		content: '';
		display: block;
		padding-top: 56.25%;
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}