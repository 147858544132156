.secondMenu {

}

.secondMenu-item {

}

.secondMenu-link {
	text-decoration: none;
	color: #000;
	font-size: 13px;
	line-height: 2.4;
}

.secondMenu-item:first-child {
	.secondMenu-link {
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
	}
}
