.certPopup {
	background: url('/img/index/set-popup-bg.png') center center;
	background-size: cover;
	width: 1000px;
	height: 550px;
	max-width: 96vw;
	max-height: 96vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.certPopup3 {
	background-size: cover;
	width: 100%;
	height: 400px;
	background: url(/img/index/ny2023-pop-up.webp) no-repeat right center;
}

@media (max-width: 576px){
	.certPopup3 {
		height: 600px;
		background: url(/img/index/pop-up-mobile.webp) no-repeat center center;
}}



.certPopup-text {
	width: 394px;
	max-width: 90%;
	font-size: 30px;
	
	@media (max-width: 600px) {
		font-size: 23px;
		
		.btn1 {
			padding: 0px 14px;
			font-size: 14px;
		}
	}
}