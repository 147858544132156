.mobileMenu-ctrl {
	display: block;
	width: 26px;
	height: 19px;
	position: relative;

	span {
		display: block;
		width: 100%;
		height: 1px;
		background: #fff;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 1;
		transition: top .1s  .1s ease-out, margin-top .1s .1s ease-out, transform .1s ease-out;

		&:nth-of-type(2) {
			top: 50%;
		}

		&:nth-of-type(3) {
			top: 100%;
		}
	}
}

.mobileMenu-ctrl_open {
	span {
		background: #64c2c9;
		transition: top .1s ease-out, margin-top .1s ease-out, transform .1s .1s ease-out;

		&:nth-of-type(1) {
			top: 50%;
			margin-top: -1px;
			transform: rotate(45deg);
		}

		&:nth-of-type(2) {
			top: 50%;
			margin-top: -1px;
			opacity: 0;
		}

		&:nth-of-type(3) {
			top: 50%;
			margin-top: -1px;
			transform: rotate(-45deg);
		}
	}
}


.mobileMenu {
	position: fixed;
	z-index: 8999;
	left: 0;
	top: 0;
	bottom: 100%;
	right: 0;
	background: #262626;
	opacity: .7;
	visibility: hidden;
	transition: visibility 0s .2s, opacity .2s linear, bottom .2s ease-out;
}

.mobileMenu_visible {
	transform: translateY(0);
	visibility: visible;
	opacity: 1;
	bottom: 0;
	transition: opacity .2s linear, bottom .2s ease-out;
}

.mobileMenu-content {
	padding: 100px 0 20px;
	box-sizing: border-box;
	height: 100%;
	overflow: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.mobileMenu-item {
	overflow: hidden;
	max-height: 43px;
	padding: 0 20px;
	transition: .3s ease-out;


	.secondMenu-link {
		color: #fff;
	}

	&.mobileMenu-item_visible {
		background: #171717;
		max-height: 400px;
		padding: 20px;
	}
}

.mobileMenu-contacts {
	color: #fff;
	position: relative;
	top: -40px;

	a {
		margin-bottom: -15px;
		display: block;
	}
}
