.bdBanner {
	@media (min-width: 800px) {
		position: fixed;
		z-index: 1;
		left: 50%;
		bottom: 0;
		max-width: 100%;
		transform: translateX(-50%);
		white-space: nowrap;
	}

	@media (max-width: 800px) {
		display: block;
		text-align: center;
		background: #262626;
	}

	color: #fff;
	text-align: center;
	font-size: 19px;
	span {
		display: inline-block;
		padding: 7px 21px 6px;

		&:nth-of-type(1) {
			background: #64c2c9;
			font-weight: 600;
		}

		&:nth-of-type(2) {
			background: #006875;
		}
	}
}

.bdBanner_ny {
	span:nth-of-type(1) {
		height: 50px;
		background: #39a7af url("/img/index/ny-banner.png") left center no-repeat;
		padding: 13px 60px 6px 190px;

		@media (max-width: 800px) {
			width: 100%;
			padding: 13px;
			text-align: center;
			background-position: -50px 50%;
		}

		@media (max-width: 500px) {
			background: #39a7af;
			font-size: 16px;
		}
	}
}