@import "../ext/mq";

.staff {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include mq($from: tablet) {
		margin-bottom: -50px;
	}
}

.staff-item {
	width: 25%;
	padding: 0 4%;
	margin: 0 0 110px;

	@include mq($until: tablet) {
		width: 100% !important;
		margin: 10px 0;
	}
	@include mq($from: mobile, $until: tablet) {
		text-align: left;
	}
	@include mq($until: mobile) {
		margin-bottom: 20px;
	}

	.btn1, .btn2, .btn3 {
		padding: 0;
		min-width: 144px;
		margin: 0 5px 5px;
	}

	img {
		width: 100% !important;
		height: auto !important;

		@include mq($from: mobile, $until: tablet) {
			width: 30%;
			margin: 0 20px 20px 0;
			float: left;
		}
	}
}

.staff-name {
	font: {
		size: 22px;
		weight: 600;
	}
	color: #262626;
	padding: 9px 20px 16px;
	line-height: 1.2;
}

.staff-role {
	font-size: 15px;
	padding: 0 0 16px;
}

.staff-popup {
	display: none;
	padding: 95px;
	overflow: auto;
	max-height: 100vh;

	@include mq($until: tablet) {
		padding: 20px 30px;
	}

	hr {
		border: none;
		background: #e0e0e0;
		height: 1px;
		margin: 30px 0;
	}

	p:last-child {
		margin-bottom: 100px;
	}

	.staff-header {
		padding-bottom: 40px;
		display: flex;
		align-items: center;

		@include mq($until: mobile) {
			display: block;
			img {
				width: 100%;
			}
		}


		.staff-name {
			font-size: 30px;
			padding-left: 75px;
			@include mq($until: mobile) {
				padding-left: 0;
			}
		}

		.staff-role {
			font-size: 18px;
			padding-left: 75px;
			@include mq($until: mobile) {
				padding-left: 0;
			}
		}
	}

}

.staff-popup_visible {
	display: block;
}










