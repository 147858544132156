@import "../sprite";

.searchForm {
	opacity: 0;
	transform: translateY(-100%);
	transition: .2s ease-out;
	display: flex;
}

.searchForm_visible {
	opacity: 1;
	transform: translateY(0);
}

.searchForm-ctrl {
	display: inline-block;
	@include sprite($search);
}

.searchForm-close {
	display: block;
	@include sprite($close);
	background-color: #e0e0e0;
}

.searchForm-input {
	width: 287px;
	height: 33px;
	box-sizing: border-box;
	border: 0;
	display: block;
	appearance: none;
	font-size: 12px;
	padding: 0 12px;
}