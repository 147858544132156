.subscriptionForm {
	text-align: center;
}

.subscriptionForm-title {
	font-weight: bold;
	font-size: 16px;
	//padding-bottom: 30px;
}

.subscriptionForm-input {
	appearance: none;
	display: block;
	margin: 0 auto;
	padding: 0 12px;
	box-sizing: border-box;
	height: 40px;
	width: 90%;
	max-width: 400px;
	border: 1px solid #b7b7b7;

}

.subscriptionForm-text {
	color: #8c8c8c;
	font-size: 12px;
	padding-top: 30px;
}
