@import "../ext/mq";

.indexPage {
	position: relative;
	height: 100vh;
	overflow: hidden;
	background: #585a5b;

	@include mq($until: mobile) {
		height: auto;
	}
}

.indexPage-bg {
	position: absolute;
	width: 100%;
	height: 100%;

	div {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: {
			size: cover;
			position: center center;
			repeat: no-repeat;
		};
	}
}


.indexPage-content {
	width: 96%;
	height: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	position: relative;
	align-items: center;
	color: #fff;

	@include mq($until: mobile) {
		height: auto;
		padding: 100px 0 80px;
		display: block;
	}

	h2 {
		font-size: 30px;
		margin-top: 0;
	}

	p {
		font-size: 16px;
		font-weight: 300;
		line-height: 1.8125;
		margin-bottom: 0;
	}

	a {
		text-decoration: none;
		color: inherit;
	}
}

.indexPage-left, .indexPage-right {
	width: 50%;
	text-align: center;
	padding: 30px 0;

	@include mq($until: mobile) {
		width: 100%;
	}
}


.indexPage-left {
	@include mq($from: mobile) {
		border-right: 1px solid #fff;
	}

	@include mq($until: mobile) {
		position: relative;
		&:after {
			content: '';
			height: 1px;
			background: #fff;
			position: absolute;
			bottom: 0;
			left: 20%;
			right: 20%;
		}
	}
}

.indexPage-bgRight {
	opacity: 0;
	transition: .2s ease-out;
}

.indexPage-bgRight_visible {
	opacity: 1;
}
