.subMenu {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0;
	list-style: none;
	overflow: hidden;


	li {
		padding: 0;
		margin: 0 0 2px 0;
		list-style: none;

		a {
			display: block;
			position: relative;
			background: #ebebeb;
			font-size: 16px;
			text-transform: uppercase;
			color: #000000;
			padding: 11px 45px 9px;
			text-decoration: none;

			&:after {
				content: '';
				position: absolute;
				left: 45px;
				right: 45px;
				bottom: 11px;
				border-bottom: 1px dashed #000;
			}
		}

		&.subMenu-currentItem {
			a {
				background: #64c2c9;
				color: #fff;
				&:after {
					display: none;
				}
			}
		}

		&:hover {
			a {
				&:after {
					display: none;
				}
			}
		}
	}
}
