@import "../ext/mq";

.popup {
	background: rgba(#000, .7);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10002;
	z-index: 10000000000;

	visibility: hidden;
	opacity: 0;
	transition: visibility 0s .2s, opacity .2s linear;
}

.popup_visible {
	visibility: visible;
	opacity: 1;
	transition: opacity .2s linear;
}

.popup-box {
	width: 90%;
	max-width: 1000px;
	background: #fff;
	position: relative;
	max-height: 100%;


	@include mq($until: tablet) {
		width: 100%;
	}

}

@import "../sprite";
.popup-close {
	position: absolute;
	right: -33px;
	top: 0;
	z-index: 20;
	@include sprite($close);

	@include mq($until: tablet) {
		right: 0;
	}
}
