// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$back-name: 'back';
$back-x: 165px;
$back-y: 100px;
$back-offset-x: -165px;
$back-offset-y: -100px;
$back-width: 21px;
$back-height: 17px;
$back-total-width: 198px;
$back-total-height: 148px;
$back-image: '../img/sprite.png?t1671190994407';
$back: (165px, 100px, -165px, -100px, 21px, 17px, 198px, 148px, '../img/sprite.png?t1671190994407', 'back', );
$check-name: 'check';
$check-x: 165px;
$check-y: 137px;
$check-offset-x: -165px;
$check-offset-y: -137px;
$check-width: 17px;
$check-height: 11px;
$check-total-width: 198px;
$check-total-height: 148px;
$check-image: '../img/sprite.png?t1671190994407';
$check: (165px, 137px, -165px, -137px, 17px, 11px, 198px, 148px, '../img/sprite.png?t1671190994407', 'check', );
$close-name: 'close';
$close-x: 75px;
$close-y: 113px;
$close-offset-x: -75px;
$close-offset-y: -113px;
$close-width: 33px;
$close-height: 33px;
$close-total-width: 198px;
$close-total-height: 148px;
$close-image: '../img/sprite.png?t1671190994407';
$close: (75px, 113px, -75px, -113px, 33px, 33px, 198px, 148px, '../img/sprite.png?t1671190994407', 'close', );
$icon-app-name: 'icon-app';
$icon-app-x: 110px;
$icon-app-y: 113px;
$icon-app-offset-x: -110px;
$icon-app-offset-y: -113px;
$icon-app-width: 33px;
$icon-app-height: 33px;
$icon-app-total-width: 198px;
$icon-app-total-height: 148px;
$icon-app-image: '../img/sprite.png?t1671190994407';
$icon-app: (110px, 113px, -110px, -113px, 33px, 33px, 198px, 148px, '../img/sprite.png?t1671190994407', 'icon-app', );
$icon-tick-name: 'icon-tick';
$icon-tick-x: 0px;
$icon-tick-y: 0px;
$icon-tick-offset-x: 0px;
$icon-tick-offset-y: 0px;
$icon-tick-width: 59px;
$icon-tick-height: 59px;
$icon-tick-total-width: 198px;
$icon-tick-total-height: 148px;
$icon-tick-image: '../img/sprite.png?t1671190994407';
$icon-tick: (0px, 0px, 0px, 0px, 59px, 59px, 198px, 148px, '../img/sprite.png?t1671190994407', 'icon-tick', );
$idea-name: 'idea';
$idea-x: 165px;
$idea-y: 70px;
$idea-offset-x: -165px;
$idea-offset-y: -70px;
$idea-width: 28px;
$idea-height: 28px;
$idea-total-width: 198px;
$idea-total-height: 148px;
$idea-image: '../img/sprite.png?t1671190994407';
$idea: (165px, 70px, -165px, -70px, 28px, 28px, 198px, 148px, '../img/sprite.png?t1671190994407', 'idea', );
$minus-name: 'minus';
$minus-x: 0px;
$minus-y: 113px;
$minus-offset-x: 0px;
$minus-offset-y: -113px;
$minus-width: 36px;
$minus-height: 35px;
$minus-total-width: 198px;
$minus-total-height: 148px;
$minus-image: '../img/sprite.png?t1671190994407';
$minus: (0px, 113px, 0px, -113px, 36px, 35px, 198px, 148px, '../img/sprite.png?t1671190994407', 'minus', );
$minus2-name: 'minus2';
$minus2-x: 165px;
$minus2-y: 0px;
$minus2-offset-x: -165px;
$minus2-offset-y: 0px;
$minus2-width: 33px;
$minus2-height: 33px;
$minus2-total-width: 198px;
$minus2-total-height: 148px;
$minus2-image: '../img/sprite.png?t1671190994407';
$minus2: (165px, 0px, -165px, 0px, 33px, 33px, 198px, 148px, '../img/sprite.png?t1671190994407', 'minus2', );
$plus-name: 'plus';
$plus-x: 38px;
$plus-y: 113px;
$plus-offset-x: -38px;
$plus-offset-y: -113px;
$plus-width: 35px;
$plus-height: 35px;
$plus-total-width: 198px;
$plus-total-height: 148px;
$plus-image: '../img/sprite.png?t1671190994407';
$plus: (38px, 113px, -38px, -113px, 35px, 35px, 198px, 148px, '../img/sprite.png?t1671190994407', 'plus', );
$plus2-name: 'plus2';
$plus2-x: 165px;
$plus2-y: 35px;
$plus2-offset-x: -165px;
$plus2-offset-y: -35px;
$plus2-width: 33px;
$plus2-height: 33px;
$plus2-total-width: 198px;
$plus2-total-height: 148px;
$plus2-image: '../img/sprite.png?t1671190994407';
$plus2: (165px, 35px, -165px, -35px, 33px, 33px, 198px, 148px, '../img/sprite.png?t1671190994407', 'plus2', );
$search-name: 'search';
$search-x: 165px;
$search-y: 119px;
$search-offset-x: -165px;
$search-offset-y: -119px;
$search-width: 16px;
$search-height: 16px;
$search-total-width: 198px;
$search-total-height: 148px;
$search-image: '../img/sprite.png?t1671190994407';
$search: (165px, 119px, -165px, -119px, 16px, 16px, 198px, 148px, '../img/sprite.png?t1671190994407', 'search', );
$social-fb-name: 'social-fb';
$social-fb-x: 61px;
$social-fb-y: 0px;
$social-fb-offset-x: -61px;
$social-fb-offset-y: 0px;
$social-fb-width: 50px;
$social-fb-height: 50px;
$social-fb-total-width: 198px;
$social-fb-total-height: 148px;
$social-fb-image: '../img/sprite.png?t1671190994407';
$social-fb: (61px, 0px, -61px, 0px, 50px, 50px, 198px, 148px, '../img/sprite.png?t1671190994407', 'social-fb', );
$social-inst-name: 'social-inst';
$social-inst-x: 0px;
$social-inst-y: 61px;
$social-inst-offset-x: 0px;
$social-inst-offset-y: -61px;
$social-inst-width: 50px;
$social-inst-height: 50px;
$social-inst-total-width: 198px;
$social-inst-total-height: 148px;
$social-inst-image: '../img/sprite.png?t1671190994407';
$social-inst: (0px, 61px, 0px, -61px, 50px, 50px, 198px, 148px, '../img/sprite.png?t1671190994407', 'social-inst', );
$social-tg-name: 'social-tg';
$social-tg-x: 52px;
$social-tg-y: 61px;
$social-tg-offset-x: -52px;
$social-tg-offset-y: -61px;
$social-tg-width: 50px;
$social-tg-height: 50px;
$social-tg-total-width: 198px;
$social-tg-total-height: 148px;
$social-tg-image: '../img/sprite.png?t1671190994407';
$social-tg: (52px, 61px, -52px, -61px, 50px, 50px, 198px, 148px, '../img/sprite.png?t1671190994407', 'social-tg', );
$social-vk-name: 'social-vk';
$social-vk-x: 113px;
$social-vk-y: 0px;
$social-vk-offset-x: -113px;
$social-vk-offset-y: 0px;
$social-vk-width: 50px;
$social-vk-height: 50px;
$social-vk-total-width: 198px;
$social-vk-total-height: 148px;
$social-vk-image: '../img/sprite.png?t1671190994407';
$social-vk: (113px, 0px, -113px, 0px, 50px, 50px, 198px, 148px, '../img/sprite.png?t1671190994407', 'social-vk', );
$social-wa-name: 'social-wa';
$social-wa-x: 113px;
$social-wa-y: 52px;
$social-wa-offset-x: -113px;
$social-wa-offset-y: -52px;
$social-wa-width: 50px;
$social-wa-height: 50px;
$social-wa-total-width: 198px;
$social-wa-total-height: 148px;
$social-wa-image: '../img/sprite.png?t1671190994407';
$social-wa: (113px, 52px, -113px, -52px, 50px, 50px, 198px, 148px, '../img/sprite.png?t1671190994407', 'social-wa', );
$spritesheet-width: 198px;
$spritesheet-height: 148px;
$spritesheet-image: '../img/sprite.png?t1671190994407';
$spritesheet-sprites: ($back, $check, $close, $icon-app, $icon-tick, $idea, $minus, $minus2, $plus, $plus2, $search, $social-fb, $social-inst, $social-tg, $social-vk, $social-wa, );
$spritesheet: (198px, 148px, '../img/sprite.png?t1671190994407', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
