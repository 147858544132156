.btn2 {
	display: inline-block;
	height: 43px;
	padding: 0 30px;
	min-width: 170px;
	box-sizing: border-box;
	background: #262626;
	color: #fff;
	text-decoration: none;
	line-height: 43px;
	text-transform: uppercase;
	text-align: center;
	font: {
		size: 16px;
		weight: 300;
	}
	transition: .2s ease-out;

	&:hover {
		background: #47a0a7;
	}

	+.btn1, +.btn3 {
		margin-left: 10px;
	}
}

.text-white .btn2 {
	background: #fff;
	color: #262626;

	&:hover {
		background: #47a0a7;
		color: #fff;
	}
}
