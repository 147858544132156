@import "../sprite";

.checkbox {
	margin-bottom: 20px;
	display: block;
}

.checkbox-input {
	display: none;
}

.checkbox-tick {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 23px;
	border: 1px solid  #b7b7b7;
	box-sizing: border-box;
	margin-right: 5px;

	&:after {
		content: '';
		position: absolute;
		left: 3px;
		top: 6px;
		transform: translateY(-20%);
		opacity: 0;
		transition: .05s ease-out;
		@include sprite($check);
	}
}

.checkbox-input:checked + .checkbox-tick {
	&:after {
		transform: translateY(0);
		opacity: 1;
	}
}

.checkbox-label {
	display: inline-block;
	vertical-align: middle;
	//color: #999999;
	font-size: 16px;
}
