@import "../ext/mq";

.actionsList {
	padding: 100px 0 50px;
}

.actionsList-item {
	display: flex;
	padding-bottom: 50px;
	flex-wrap: wrap;

	&:nth-of-type(2n+1) {
		.actionsList-content {
			padding-left: 5%;
		}
	}
	&:nth-of-type(2n+2) {
		.actionsList-content {
			padding-right: 5%;
			@include mq($from: mobile) {
				order: -1;
			}
		}
	}
}

.actionsList-img {
	display: block;
	width: 58%;
	overflow: hidden;

	@include mq($until: tablet) {
		width: 42%;
	}
	@include mq($until: mobile) {
		width: 100%;
	}

	img {
		object-fit: cover;
		object-position: center center;
		width: 100%;
		height: 100%;
	}
}

.actionsList-content {
	display: flex;
	width: 42%;
	@include mq($until: tablet) {
		width: 58%;
	}
	@include mq($until: mobile) {
		width: 100%;
	}

	box-sizing: border-box;
	justify-content: center;
	flex-direction: column;
}

.actionsList-dates,
p.actionsList-dates {
	font-size: 22px;
	color: #999999;
	text-transform: uppercase;
}

.actionsList-name {
	font-size: 22px;
	font-weight: 600;
	text-transform: uppercase;
	margin:  5px 0 26px;
}

.actionsList-text {
	font-size: 15px;
	margin-bottom: 47px;

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.actionsList-btns {
	.btn1, .btn2, .btn3 {
		margin: 0 10px 5px 0;
	}
}
