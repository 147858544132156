.catalogMenu {
	background: #262626;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	padding-top: 90px;
	display: none;
	box-shadow: 0 0 5px rgba(#000, .6);
}

.catalogMenu-contents {
	position: relative;
	padding: 66px 0 40px;
	display: flex;

	&:before, &:after {
		content: '';
		position: absolute;
		left: 33.333%;
		top: 0;
		bottom: 0;
		right: -100%;
		background: #f4f4f4;
	}

	&:after {
		left: -100%;
		right: 66.666%;
		background: #e3e3e3;
	}
}

.catalogMenu-left {
	position: relative;
	z-index: 1;
	width: 33.333%;
}

.catalogMenu-right {
	position: relative;
	z-index: 1;
	width: 66.666%;
	height: 100%;
	overflow: hidden;
	padding-left: 9%;
}

.catalogMenu-div {
	display: none;
	&:first-child {
		display: block;
	}
}

.catalogMenu-title {
	//transform: translateY(-20px);
	transition: .4s ease-out;
	padding-bottom: 28px;
	a {
		color: #000;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 400;
		text-decoration: none;
		&:hover, &._current {
			color: #64c2c9;
		}
	}
}

.catalogMenu-item {
	//position: relative;
	//transform: translateY(-20px);
	width: 50%;
	float: left;
	box-sizing: border-box;
	padding-left: 4.8%;
	padding-bottom: 3px;
	a {
		position: relative;
		color: #000;
		font-weight: 400;
		font-size: 15px;
		text-decoration: none;
		&:hover {
			color: #64c2c9;
		}
	}
}

.catalogMenu_visible {
	.catalogMenu-title,
	.catalogMenu-item {
		transform: translateY(0);
	}
}

.catalogMenu-tags {
	transform: translateX(-6px);
	white-space: nowrap;
	position: absolute;
	right: 100%;
	top: -3px;
}

.catalogMenu-tag {
	font-weight: 600;
	font-size: 11px;
	text-transform: uppercase;
	color: #fff;
	height: 14px;
	line-height: 14px;
	padding: 0 3px;
	display: inline-block;

	&.catalogMenu-tag_new {
		background: #64c2c9;
		&:after {
			content: 'new';
		}
	}

	&.catalogMenu-tag_percent{
		background: #3e8f8d;
		&:after {
			content: '%';
		}
	}
}











