.mainMenu {

 }

.mainMenu-item {
	display: inline-block;
	padding-right: 40px;
	white-space: nowrap;
}

.mainMenu-link {
	display: block;
	position: relative;
	text-decoration: none;
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
	transition: .15s ease-out;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 32px;
		transform: translateX(-50%);
		width: 60px;
		max-width: 90%;
		height: 2px;
		background: rgba(#64c2c9, 0);
		pointer-events: none;
		transition: .15s ease-out;
	}
}


.mainMenu-link_current {
	color: #64c2c9;
	&:after {
		background: rgba(#64c2c9, 1);
	}
}

@media (max-width: 1150px) {
	.mainMenu-link {
		font-size: 14px;

		&:after {
			display: none;
		}
	}
}
