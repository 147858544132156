.slashDecor {
	padding-left: 115px;
	position: relative;

	&:before {
		position: absolute;
		left: 47px;
		top: 5px;
		content: '';
		width: 11px;
		height: 70px;
		background: #64c2c9;

		transform: skewX(-30deg);
	}
}