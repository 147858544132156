.input {
	margin-bottom: 20px;
	display: block;
}

.input-label {
	display: block;
	//color: #999999;
	font-size: 16px;
	padding-bottom: 6px;
}

.input-input {
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #b7b7b7;
	appearance: none;
	height: 46px;
	padding: 0 16px;


}


.input-input._error, .input .input-input_error, .popupForm-error .input-input, .popupForm-error .popupForm-shakeable  {
	box-shadow: 0 0 5px rgba(red, .9);
	animation: {
		name: shake;
		duration: .1s;
		iteration-count: 3;
	}
}
