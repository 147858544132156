@import "../ext/mq";

.price {
	max-width: 768px;
	margin: 12px auto 30px;
	overflow:auto;
}

.price-row {
	overflow: hidden;
	position: relative;
	padding: 0 0 9px;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 16px;
		width: 100%;
		height: 1px;
		background: #e5e5e5;
	}
}


.price-cell {
	float: left;
	font-size: 20px;
	@include mq($until: tablet) {
		font-size: 18px;
	}
	position: relative;
	background: #fff;

	&:nth-of-type(1) {
		font-size: 18px;
		padding-right: 6px;
		@include mq($until: tablet) {
			font-size: 16px;
		}
	}

	&:nth-of-type(2) {
		float: right;
		padding-left: 6px;
	}


}


.price-header {
	padding: 0 0 28px;

	.price-cell:nth-of-type(1) {
		font-size: 20px;
		@include mq($until: tablet) {
			font-size: 18px;
		}
	}
	.price-cell:nth-of-type(1) {
		font-weight: 600;
	}

	&:before {
		display: none;
	}
}
