.list1 {
	display: block;
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		display: block;
		padding: 0 0 0 15px;
		margin: 0 0 20px;
		list-style: none;
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 9px;
			width: 10px;
			height: 2px;
			background: #7fcbd1;
		}
	}
}
